import classes from '../VersionItem.module.scss';
import { Avatar, Group, Stack, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { ReviewStatus } from '@assemblio/type/revision';
import { IconCircleCheckFilled, IconCircleXFilled, IconRefresh } from '@tabler/icons-react';
import { ExpandableText } from '../../../../../../../../../components/ExpandableText/ExpandableText';

interface RevisionActionDisplayProps {
  primaryUserName: string;
  secondaryUserName: string;
  state: ReviewStatus;
  comment: string;
}

export const RevisionActionDisplay = ({
  primaryUserName,
  secondaryUserName,
  state,
  comment,
}: RevisionActionDisplayProps) => (
  <Group className={classes.versionItem__action}>
    <Avatar.Group className={classes.avatar__stack}>
      <Avatar color={'initials'} mb={'xs'} radius={'100%'} size="md" name={primaryUserName} />
      <Avatar radius={'100%'} size={'xs'}>
        {StateIconMap[state]}
      </Avatar>
      <Avatar color={'initials'} mb={'xs'} radius={'100%'} size="sm" name={secondaryUserName} />
    </Avatar.Group>
    <Stack h={'100%'} justify={'center'} gap={'xs'}>
      <Text size="md" fw={'bold'} lineClamp={1}>
        {primaryUserName}&nbsp;
        <Text span size="md">
          {ActionWordingMap[state] + secondaryUserName}
        </Text>
      </Text>
      {comment && <ExpandableText text={comment} />}
    </Stack>
  </Group>
);

type StateIconMapType = {
  [key in ReviewStatus]: ReactNode;
};
const StateIconMap: StateIconMapType = {
  [ReviewStatus.APPROVED]: <IconCircleCheckFilled size={14} />,
  [ReviewStatus.REJECTED]: <IconCircleXFilled size={14} />,
  [ReviewStatus.IN_REVIEW]: <IconRefresh size={14} />,
};

type ActionWordingMapType = {
  [key in ReviewStatus]: string;
};
const ActionWordingMap: ActionWordingMapType = {
  [ReviewStatus.REJECTED]: 'rejected assembly version from ',
  [ReviewStatus.IN_REVIEW]: 'requested review for this assembly from ',
  [ReviewStatus.APPROVED]: 'approved new version from ',
};
