import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class CreateTenantDto {
  @ApiProperty({
    description: 'Tenant name',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;
}

export const CreateTenantSchema = generateClassValidationObject(CreateTenantDto);
