import { useEffect } from 'react';
import { EventController } from '@assemblio/frontend/stores';
import { useQueryClient } from '@tanstack/react-query';
import { ProductMessage, RoomEvents } from '@assemblio/type/socket';
import { ProductContentDto, RevisionDto, UserSummaryDto } from '@assemblio/shared/dtos';
import produce from 'immer';
import { InstructionImageSize, InstructionState } from '@assemblio/type/instruction';

const REVIEW_AFFECTED_STATES = [
  InstructionState.IN_REVIEW,
  InstructionState.DRAFT,
  InstructionState.REQUEST_CHANGE,
  InstructionState.APPROVED,
];

export const useProductWebsocketListener = (productId: string | undefined, projectId: string | undefined) => {
  const queryClient = useQueryClient();

  const handleProductUpdate = (event: ProductMessage) => {
    queryClient.setQueryData<ProductContentDto>(['product', productId], (data?: ProductContentDto) =>
      produce(data, (draft) => {
        if (!draft) return draft;
        const { message, coverUpdated, ...productChanges } = event.payload;

        if (coverUpdated)
          queryClient.invalidateQueries(
            ['instruction-image', draft.id, InstructionImageSize.LARGE],
            {
              refetchType: 'all',
            },
            {
              cancelRefetch: false,
            }
          );

        if (draft.hasSteps && isRevisionState(event.payload.state)) {
          queryClient.invalidateQueries(['product-revisions', event.target.productId]);
          queryClient.invalidateQueries(['product-revision', event.target.productId]);
        }
        if (coverUpdated) queryClient.invalidateQueries(['instruction-image', draft.id, InstructionImageSize.LARGE]);

        productChanges.state && (draft.state = productChanges.state);
        productChanges.ownedBy && (draft.ownedBy = productChanges.ownedBy);
        productChanges.version && (draft.version = productChanges.version);
        productChanges.reviewerId &&
          (draft.revision = {
            ...draft.revision,
            reviewer: { ...draft.revision?.reviewer, id: productChanges.reviewerId } as UserSummaryDto,
          } as RevisionDto);
      })
    );
  };

  useEffect(() => {
    if (!productId || !projectId) return;
    EventController.subscribeRoom(
      {
        roomEvent: RoomEvents.Product,
        projectId,
        productId,
      },
      handleProductUpdate
    );

    return () => {
      EventController.unsubscribeRoom({
        roomEvent: RoomEvents.Product,
        projectId,
        productId,
      });
    };
  }, [productId, projectId]);
};

const isRevisionState = (state?: string) =>
  state ? Object.values(REVIEW_AFFECTED_STATES).includes(state as InstructionState) : false;
