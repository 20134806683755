import { GLTF, GLTFLoader } from 'three-stdlib';
import { axiosAPIKeyClient } from '../utils/axios-shared.utils';
import { InstructionTreeDto } from '@assemblio/shared/next-types';
import { AnnotationResponseDto, InstructionDto } from '@assemblio/shared/dtos';

export const fetchInstructionAPI = async (instructionId: string | undefined): Promise<InstructionDto> => {
  return typeof instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosAPIKeyClient({
        method: 'get',
        url: `/instructions/${instructionId}`,
      }).then((response) => response.data);
};

export const fetchAnnotationsAPI = async (instructionId: string): Promise<Array<AnnotationResponseDto>> => {
  return axiosAPIKeyClient({
    method: 'get',
    url: `/instructions/${instructionId}/annotations`,
  }).then((response) => response.data);
};

export const fetchInstructionPartsAPI = async (id: string | undefined): Promise<InstructionTreeDto> => {
  return typeof id === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosAPIKeyClient({
        method: 'get',
        url: `/parts/instruction/${id}/tree`,
      }).then((response) => response.data);
};

export const loadInstructionGltf = async (instructionId: string | undefined): Promise<GLTF> => {
  return typeof instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosAPIKeyClient<ArrayBuffer>({
        method: 'get',
        url: `/v1/instructions/${instructionId}/files/gltf`,
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'application/octet-stream' },
      })
        .then((response) => response.data)
        .then((data) => {
          return new Promise((resolve, reject) => {
            const loader = new GLTFLoader();
            loader.parse(
              data,
              '',
              (gltf: GLTF) => resolve(gltf),
              (event) => reject(event.error)
            );
          });
        });
};

export const fetchInstructionSteps = async (query: {
  instructionId?: string;
  reverseOrder: boolean;
}): Promise<any[]> => {
  return typeof query.instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosAPIKeyClient({
        method: 'get',
        url: `/v1/instructions/${query.instructionId}/step-groups`,
        params: { reverse_order: query.reverseOrder },
      }).then((response) => {
        return response.data;
      });
};
