import { useQuery } from '@tanstack/react-query';
import { fetchProductRevisions, fetchLatestProductRevision } from './instruction.review.requests';

export const useFetchRevisions = (productId: string | undefined) => {
  return useQuery(['product-revisions', productId], () => {
    return fetchProductRevisions(productId);
  });
};
export const useFetchLatestRevision = (instructionId: string | undefined) => {
  return useQuery(['product-revision', instructionId], () => {
    return fetchLatestProductRevision(instructionId);
  });
};
