import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { ReviewStatus } from '@assemblio/type/revision';
import { OwnerSchema, UserSummaryDto } from '../user';

export class RevisionDto {
  @ApiProperty({
    description: 'The revision id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The revision State',
    enum: ReviewStatus,
    required: true,
  })
  @joiSchemaProperty(Joi.string().valid(...Object.values(ReviewStatus)))
  state!: ReviewStatus;

  @ApiProperty({
    description: 'The reviewer',
    required: true,
  })
  @joiSchemaProperty(OwnerSchema)
  reviewer!: UserSummaryDto;

  @ApiProperty({
    description: 'The requestee',
    required: true,
  })
  @joiSchemaProperty(OwnerSchema)
  requestee!: UserSummaryDto;
}

export const RevisionSchema = generateClassValidationObject(RevisionDto);
