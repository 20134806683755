import {
  fetchAnnotationsAPI,
  fetchInstructionAPI,
  fetchInstructionPartsAPI,
  fetchInstructionSteps,
  loadInstructionGltf,
} from '@assemblio/frontend/data-access';
import { Book, Page, StepGroupDto } from '@assemblio/shared/dtos';
import { DocumentExportMessage } from '@assemblio/shared/next-types';
import { GLTF } from 'three-stdlib';
import { assertEvent, assign } from 'xstate';
import { AnimationController, ModelController } from '../controller';
import { createLineMesh } from '../helper';
import { pdfActor } from './pdf.machine';
import { FetchInstructionResult, PdfContext, PdfEvent } from './types/pdf.machine.types';

export const projectHasSteps = ({ context }: { context: PdfContext }): boolean => {
  // TODO: Fix Exporters with new requests
  // const sequence = useVideoRendererStore.getState().sequence;
  // return sequence !== undefined && sequence[0].steps.length > 0;

  return context.stepGroups !== undefined && context.stepGroups.length > 0;
};

export const applyStepGroupSelection = ({ context }: { context: PdfContext }) => {
  if (!context.exportMessage || !context.stepGroups) return;
  const clipIds = context.exportMessage.selection.reduce((clipIds, { sequenceId, stepGroupIds }) => {
    return clipIds.concat(
      stepGroupIds
        .flatMap((id) => {
          const group = context.stepGroups?.find((group) => {
            const stepGroup = group as StepGroupDto;
            return stepGroup.instructionSequenceId === sequenceId && stepGroup.id === id;
          }) as StepGroupDto | undefined;
          if (group) {
            return group.steps.flatMap((step) => {
              const clips = AnimationController.getClipIdForStepId(step.id);
              return step.playWithAbove ? [] : [clips?.main, clips?.prelude];
            });
          }
          return undefined;
        })
        .filter((value) => value !== undefined) as Array<string>
    );
  }, new Array<string>());
  AnimationController.applySequence(clipIds);
};

export const getEmptyContext = (): PdfContext => ({
  exportMessage: undefined,
  instruction: undefined,
  instructionParts: undefined,
  stepGroups: undefined,
  annotations: undefined,
  gltf: undefined,
});

export const fetchInstruction = async (context: PdfContext): Promise<FetchInstructionResult> => {
  if (!context.exportMessage) {
    pdfActor.send({
      type: 'ERROR',
      message: `Error fetching instruction from server.`,
    });
    return Promise.reject();
  }

  const instructionId = context.exportMessage.instructionId;
  const instruction = await fetchInstructionAPI(instructionId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      pdfActor.send({
        type: 'ERROR',
        message: `Error fetching instruction ${instructionId} from server.`,
      });
      return undefined;
    });
  if (!instruction) return Promise.reject();
  const instructionParts = await fetchInstructionPartsAPI(instructionId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      pdfActor.send({
        type: 'ERROR',
        message: `Error fetching instruction ${instructionId} from server.`,
      });
      return undefined;
    });
  if (!instructionParts) return Promise.reject();
  const stepGroups = await Promise.all(
    context.exportMessage.selection.map((selection) => {
      return fetchInstructionSteps({
        instructionId: instruction.id,
        reverseOrder: false,
      });
    })
  )
    .then((result) => {
      return result.flat();
    })
    .catch((error) => {
      console.log(error);
      pdfActor.send({
        type: 'ERROR',
        message: `Error fetching step groups from server.`,
      });
      return undefined;
    });
  if (!stepGroups) return Promise.reject();
  const gltf = await loadInstructionGltf(instruction.id)
    .then((result) => result)
    .catch((error) => {
      console.log(error);
      pdfActor.send({
        type: 'ERROR',
        message: `Error fetching GLTF from server.`,
      });
      return undefined;
    });
  if (!gltf) return Promise.reject();
  const annotations = await fetchAnnotationsAPI(instructionId)
    .then((result) => result)
    .catch((error) => {
      console.debug(error);
      pdfActor.send({
        type: 'ERROR',
        message: `Error fetching Annotations from server.`,
      });
      return undefined;
    });
  if (annotations === undefined) return Promise.reject();
  return { gltf, stepGroups, instructionParts, instruction, annotations };
};

export const downloadGLTF = async (context: PdfContext) => {
  if (!context.instruction) {
    pdfActor.send({
      type: 'ERROR',
      message: 'Instruction not initialized on Context.',
    });
    return;
  }

  const fileId = context.instruction.id;
  await loadInstructionGltf(fileId)
    .then((gltf: GLTF) => {
      assign({ gltf });
      createLineMesh(gltf.scene);
    })
    .catch((error) => {
      console.log(error);
      pdfActor.send({
        type: 'ERROR',
        message: `Failed loading GLTF file ${fileId} from server.`,
      });
    });
};

const compilePageInformation = (context: PdfContext): Book | undefined => {
  if (!context.exportMessage) return;
  const chapters = context.exportMessage.selection.map(({ sequenceId, stepGroupIds }) => {
    return {
      sequence: sequenceId,
      name: context.instruction?.instructionSequences.find((sequence) => sequence.id === sequenceId)?.name || '',
      pages: stepGroupIds
        .flatMap((id) => {
          const group = context.stepGroups?.find((group) => {
            const stepGroup = group as StepGroupDto;
            return stepGroup.instructionSequenceId === sequenceId && stepGroup.id === id;
          }) as StepGroupDto | undefined;
          if (group) {
            return group.steps.reduce((pages, step) => {
              const partNames = step.data
                ? step.data.parts.reduce((partNames, part) => {
                    let name = ModelController.getPartNameOverrideByGltfIndex(part.partGltfIndex);
                    name = name.replace(/\.\d+$/g, '');
                    let existingIndex = partNames.findIndex((item) => item.name === name);
                    if (existingIndex < 0) {
                      existingIndex = partNames.push({ name, count: 0 }) - 1;
                    }
                    const existingName = partNames.at(existingIndex);
                    if (existingName) {
                      existingName.count++;
                    }
                    return partNames;
                  }, new Array<{ name: string; count: number }>())
                : [];
              if (step.playWithAbove) {
                const previousPage = pages.at(-1);
                if (previousPage) {
                  previousPage.steps.push({ id: step.id, name: step.name });
                  partNames.forEach((newPart) => {
                    const index = previousPage.parts.findIndex((prevPart) => prevPart.name === newPart.name);
                    if (index !== -1) {
                      // If same part already exists in previous Step, increase the count
                      previousPage.parts[index].count += newPart.count;
                    } else {
                      // If the part is not found, concatenate it
                      // Note: We create a new object to avoid mutating the newPart object
                      previousPage.parts = previousPage.parts.concat({
                        ...newPart,
                      });
                    }
                  });
                  if (step.text) {
                    if (previousPage.texts) {
                      previousPage.texts = [step.text].concat(previousPage.texts);
                    } else {
                      previousPage.texts = [step.text];
                    }
                  }
                }
              } else {
                pages.push({
                  group: { name: group.name, id: group.id },
                  tools: [], // ToDo: add the tools
                  resources: [], // ToDo: add the resources
                  steps: [{ id: step.id, name: step.name }],
                  parts: partNames,
                  texts: step.text ? [step.text] : undefined,
                });
              }
              return pages;
            }, new Array<Page>());
          }
          return undefined;
        })
        .reverse()
        .filter((page) => page !== undefined) as Array<Page>,
    };
  });
  return {
    chapters,
    partCount: chapters.reduce(
      (count, chapter) =>
        count +
        chapter.pages.reduce((count, page) => count + page.parts.reduce((count, part) => count + part.count, 0), 0),
      0
    ),
    stepCount: chapters.reduce((count, chapter) => count + chapter.pages.length, 0),
  };
};

export const notifyReadyToRender = ({ context }: { context: PdfContext }) => {
  if (!context.exportMessage || !context.instruction || !context.instructionParts || !context.stepGroups) {
    pdfActor.send({
      type: 'ERROR',
      message: 'Data missing from PDF Context',
    });
    return;
  }
  const book = compilePageInformation(context);
  if (book) {
    window.readyToRender(book);
  }
};

export const notifyReadyToCapture = () => {
  console.log('[pdf.actions] notifyReadyToCapture');
  const stepIds = AnimationController.getCurrentStepIds();
  const progress = AnimationController.getAnimationProgress();
  window.readyToCapture(stepIds ? stepIds : [], progress);
};

export const notifyReadyToCaptureCover = () => {
  const stepIds = AnimationController.getCurrentStepIds();
  const progress = AnimationController.getAnimationProgress();
  window.readyToCaptureCover(stepIds ? stepIds : [], progress);
};

export const notifyComplete = () => {
  console.log('[pdf.actions] notifyComplete');
  window.finished();
};

export const notifyError = ({ event }: { event: PdfEvent }) => {
  console.log('[video.actions] notifyError');
  assertEvent(event, 'ERROR');
  const message = event.message;
  window.error(message);
};

export const makeModelVisible = () => {
  //ModelController.setAllPartsVisible();
};

export const registerEventHandlers = () => {
  console.log('[pdf.actions] Registering event handlers');

  document.body.addEventListener('loadProject', (event: Event) => {
    const exportEvent = event as CustomEvent<DocumentExportMessage>;
    console.log('[pdf.actions] received loadProject', exportEvent.detail);
    pdfActor.send({
      type: 'PREPARE',
      message: exportEvent.detail,
    });
    console.log('[pdf.actions] MACHINE SEND PREPARE');
  });
  document.body.addEventListener('readyToReceive', (event: Event) => {
    console.log('[pdf.actions] received readyToReceive', event);
    pdfActor.send({ type: 'START' });
    console.log('[pdf.actions] MACHINE SEND START');
  });
  document.body.addEventListener('frameRecorded', (event: Event) => {
    console.log('[pdf.actions] received frameRecorded', event);
    pdfActor.send({ type: 'CAPTURED' });
    console.log('[pdf.actions] MACHINE SEND CAPTURED');
  });
};

export const createAnimations = () => {
  AnimationController.createAnimations({
    onAnimationComplete: () => {
      pdfActor.send({ type: 'STOP' });
    },
    omitCameraAnimations: true,
  });
};

export const checkLastSequenceStep = () => {
  if (AnimationController.finished()) {
    // This was the last step
    pdfActor.send({ type: 'STOP' });
    console.log('[pdf.machine] MACHINE STOP (LAST STEP DETECTED)');
  } else {
    pdfActor.send({ type: 'CONTINUE' });
    console.log('[pdf.machine] MACHINE CONTINUE');
  }
};
