import { notifications } from '@mantine/notifications';
import { useRequestProductChangesMutation } from '@assemblio/frontend/data-access';

export const useRequestProductChanges = () => {
  const requestChangesMutation = useRequestProductChangesMutation();

  const submitRequestChanges = (productId: string | undefined, revisionId: string | undefined) => {
    if (!productId || !revisionId) return;
    requestChangesMutation.mutate(
      { productId, revisionId },
      {
        onError: () => {
          notifications.show({
            id: 'request-changes-error',
            message: 'Failed to request changes',
            color: 'red',
          });
        },
        onSuccess: () => {
          notifications.show({
            id: 'request-changes-success',
            message: 'Requested changes',
          });
        },
      }
    );
  };

  return {
    onRequestChanges: submitRequestChanges,
    isLoading: requestChangesMutation.isLoading,
  };
};
