import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import {
  bulkSetPartColor,
  bulkSetPartGroupName,
  bulkSetPartName,
  renamePart,
  renamePartGroup,
  resetPartColor,
  resetPartGroupsName,
  resetPartsName,
  setPartColor,
} from './parts.requests';
import { PartsColor, PartGroupRename, PartRename, PartColor, PartOrGroupNames } from './types/parts.mutation.types';
import { IdsDto } from '@assemblio/shared/dtos';

export const usePartRename = () => {
  return useMutation((patch: PartRename) => renamePart(patch), {
    onError: (_error) => {
      notifications.show({
        id: 'rename-part',
        message: 'Part could not be renamed',
        color: 'red',
      });
    },
  });
};

export const usePartNameReset = () => {
  return useMutation((ids: IdsDto) => resetPartsName(ids), {
    onError: (_error) => {
      notifications.show({
        id: 'rename-part',
        message: 'Part could not be renamed',
        color: 'red',
      });
    },
  });
};

export const useBulkSetPartName = () => {
  return useMutation((patch: PartOrGroupNames[]) => bulkSetPartName(patch), {
    onError: (_error) => {
      notifications.show({
        id: 'set-part-names',
        message: 'Parts could not be modified',
        color: 'red',
      });
    },
  });
};

export const usePartGroupRename = () => {
  return useMutation((patch: PartGroupRename) => renamePartGroup(patch), {
    onError: (_error) => {
      notifications.show({
        id: 'rename-part-group',
        message: 'Part Group could not be renamed',
        color: 'red',
      });
    },
  });
};

export const useBulkSetPartGroupName = () => {
  return useMutation((patch: PartOrGroupNames[]) => bulkSetPartGroupName(patch), {
    onError: (_error) => {
      notifications.show({
        id: 'set-part-names',
        message: 'Part Groups could not be modified',
        color: 'red',
      });
    },
  });
};

export const usePartGroupNameReset = () => {
  return useMutation((ids: IdsDto) => resetPartGroupsName(ids), {
    onError: (_error) => {
      notifications.show({
        id: 'rename-part-group',
        message: 'Part Group could not be renamed',
        color: 'red',
      });
    },
  });
};

export const useSetPartColor = () => {
  return useMutation((patch: PartsColor) => setPartColor(patch), {
    onError: (_error) => {
      notifications.show({
        id: 'set-part-color',
        message: 'Part could not be modified',
        color: 'red',
      });
    },
  });
};

export const useBulkSetPartColor = () => {
  return useMutation((patch: PartColor[]) => bulkSetPartColor(patch), {
    onError: (_error) => {
      notifications.show({
        id: 'set-part-color',
        message: 'Part could not be modified',
        color: 'red',
      });
    },
  });
};

export const useResetPartColor = () => {
  return useMutation((ids: IdsDto) => resetPartColor(ids), {
    onError: (_error) => {
      notifications.show({
        id: 'set-part-color',
        message: 'Part could not be modified',
        color: 'red',
      });
    },
  });
};
