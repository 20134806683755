import { StepController, useSequenceStore, useUIStore } from '@assemblio/frontend/stores';
import { MultiTextRenderer } from './TextRenderer';
import { ActionIcon, Paper, Popover, rem, ScrollArea, SegmentedControl } from '@mantine/core';
import { IconArrowLeft, IconArrowRight, IconSettingsFilled } from '@tabler/icons-react';
import { useState } from 'react';

export const SidebarTextRenderer = () => {
  const [position, setPosition] = useState('left');
  const [opened, setOpened] = useState(false);
  const selectedStep = useUIStore((store) => store.selectedStep);

  const step = useSequenceStore((state) => {
    if (selectedStep) {
      return state.stepGroups[selectedStep.index.stepGroupIndex].steps[selectedStep.index.stepIndex];
    } else return undefined;
  });
  const iconProps = {
    style: { width: rem(16), height: rem(16), display: 'block' },
  };

  if (selectedStep && step) {
    const texts = StepController.getStepTexts(selectedStep.id);
    if (texts.length > 0) {
      return (
        <Paper
          style={{
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'var(--text-constant)',
            pointerEvents: 'auto',
            display: 'flex',
            flexDirection: 'column',
            ...getPositionalStyles(position),
          }}
          p="md"
        >
          <Popover opened={opened} onChange={setOpened}>
            <Popover.Target>
              <ActionIcon
                style={{
                  alignSelf: position === 'left' ? 'flex-end' : 'flex-start',
                }}
                variant="transparent"
                c={'var(--text-constant)'}
                size={'xs'}
                onClick={() => setOpened((o) => !o)}
              >
                <IconSettingsFilled />
              </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown>
              <SegmentedControl
                withItemsBorders={false}
                value={position}
                size="xs"
                data={[
                  {
                    value: 'left',
                    label: <IconArrowLeft {...iconProps} />,
                  },
                  {
                    value: 'right',
                    label: <IconArrowRight {...iconProps} />,
                  },
                ]}
                onChange={(value) => {
                  setPosition(value);
                  setOpened(false);
                }}
              />
            </Popover.Dropdown>
          </Popover>
          <ScrollArea.Autosize scrollbars="y" offsetScrollbars>
            <MultiTextRenderer texts={texts} />
          </ScrollArea.Autosize>
        </Paper>
      );
    }
  }
  return undefined;
};

const getPositionalStyles = (position: string) => {
  return {
    maxWidth: '350px',
    height: '100%',
    left: position === 'left' ? 0 : 'auto',
    right: position === 'right' ? 0 : 'auto',
  };
};
