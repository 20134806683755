import { InstructionState } from '@assemblio/type/instruction';
import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { SyncProfileDto, SyncProfileSchema } from '../user/sync-profile.dto';
import { UserDto, UserSchema } from '../user/response.dto';
import { SequenceDto, SequenceSchema } from '../sequence/instruction-sequence.dto';
import { InstructionFileDto, InstructionFileSchema } from '../instruction-files/file.dto';

export class InstructionEditorSettingsDto {
  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'port color', required: true, type: Number })
  viewPortColor!: number;

  @joiSchemaProperty(Joi.boolean().required())
  @ApiProperty({ description: 'Enable grid?', required: true, type: Boolean })
  gridEnabled!: boolean;

  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'grid color', required: true, type: Number })
  gridColor!: number;

  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'gird thickness', required: true, type: Number })
  gridThickness!: number;

  @joiSchemaProperty(Joi.boolean().required())
  @ApiProperty({ description: 'enable table', required: true, type: Boolean })
  tableEnabled!: boolean;

  @joiSchemaProperty(Joi.number().required())
  @ApiProperty({ description: 'table color', required: true, type: Number })
  tableColor!: number;
}

export const InstructionEditorSettingSchema = generateClassValidationObject(InstructionEditorSettingsDto);

export class InstructionFolderDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'folder Id', required: true })
  id!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'folder Name', required: true })
  name!: string;
}

export const InstructionFolderSchema = generateClassValidationObject(InstructionFolderDto);

export class InstructionProjectDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'project Id', required: true })
  id!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'project Name', required: true })
  name!: string;
}

export const InstructionProjectSchema = generateClassValidationObject(InstructionProjectDto);

export class InstructionDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Instruction Id', required: true })
  id!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Instruction Name', required: true })
  name!: string;

  @joiSchemaProperty(UserSchema.required())
  @ApiProperty({ description: 'Creator data', required: true, type: UserDto })
  creator!: UserDto;

  @joiSchemaProperty(UserSchema.required())
  @ApiProperty({ description: 'owner data', required: true, type: UserDto })
  owner!: UserDto;

  @joiSchemaProperty(Joi.string().valid(...Object.values(InstructionState)))
  @ApiProperty({ description: 'Instruction state', required: false, enum: InstructionState })
  state!: InstructionState;

  @joiSchemaProperty(Joi.array().items(SyncProfileSchema).required())
  @ApiProperty({ description: 'sync profile', required: true, type: [SyncProfileDto] })
  syncSource!: SyncProfileDto[];

  @joiSchemaProperty(Joi.array().items(SequenceSchema).required())
  @ApiProperty({ description: 'secuence', required: true, type: [SequenceDto] })
  instructionSequences!: SequenceDto[];

  @joiSchemaProperty(Joi.array().items(InstructionFileSchema))
  @ApiProperty({ description: 'file data', required: false, type: [InstructionFileDto] })
  files?: InstructionFileDto[];

  @joiSchemaProperty(InstructionEditorSettingSchema)
  @ApiProperty({ description: 'Settings', required: false, type: InstructionEditorSettingsDto })
  editorSettings?: InstructionEditorSettingsDto;

  @joiSchemaProperty(InstructionProjectSchema.required())
  @ApiProperty({ description: 'Instruction Project data', required: true, type: InstructionProjectDto })
  project!: InstructionProjectDto;

  @joiSchemaProperty(InstructionFolderSchema.required())
  @ApiProperty({ description: 'Instruction folder data', required: true, type: InstructionFolderDto })
  folder!: InstructionFolderDto;

  @joiSchemaProperty(Joi.date().required())
  @ApiProperty({ description: 'Last update date', required: true })
  updatedAt!: Date;
}

export const InstructionSchema = generateClassValidationObject(InstructionDto);
