import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { ReviewStatus } from '@assemblio/type/revision';
import { OwnerSchema, UserSummaryDto } from '../user';
import { ProductVersionType } from '@assemblio/type/instruction';

export class RevisionHistoryDto {
  @ApiProperty({
    description: 'The product id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  productId!: string;

  @ApiProperty({
    description: 'The revision id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  revisionId!: string;

  @ApiProperty({
    description: 'The revision State',
    enum: ReviewStatus,
    required: true,
  })
  @joiSchemaProperty(Joi.string().valid(...Object.values(ReviewStatus)))
  state!: ReviewStatus;

  @ApiProperty({
    description: 'The version type',
    enum: ProductVersionType,
    required: true,
  })
  @joiSchemaProperty(Joi.string().valid(...Object.values(ProductVersionType)))
  type!: ProductVersionType;

  @ApiProperty({
    description: 'The reviewer',
    required: true,
  })
  @joiSchemaProperty(OwnerSchema)
  reviewer!: UserSummaryDto;

  @ApiProperty({
    description: 'The requestee',
    required: true,
  })
  @joiSchemaProperty(OwnerSchema)
  requestee!: UserSummaryDto;

  @ApiProperty({
    description: 'The revision version',
    required: true,
  })
  version!: number;

  @ApiProperty({
    description: 'The revision description',
    required: true,
  })
  comment!: string;

  @ApiProperty({
    description: 'The creation date',
    required: true,
  })
  createdAt!: string;

  @ApiProperty({
    description: 'The last update and changed date',
    required: true,
  })
  updatedAt!: string;
}

export const RevisionHistorySchema = generateClassValidationObject(RevisionHistoryDto);
