import { setup } from 'xstate';
import {
  activateIsAnimating,
  cacheCameraTransform,
  createAnimations,
  deactivateIsAnimating,
  nextClip,
  pauseAnimation,
  playAnimation,
  previousClip,
  restoreCameraTransform,
  resumeAnimation,
  skipBackward,
  skipForward,
  stopAnimation,
  unhighlightAllSteps,
} from './animation.actions';
import {
  hideAllAnnotations,
  hideAnnotationsInPlayingSequence,
  hideAnnotationsInSelectedStep,
  isNotEditingAnnotation,
  showAnnotationsInPlayingSequence,
  showAnnotationsInSelectedStep,
  stopEditingAnnotations,
  unhighlightAllAnnotations,
} from './annotation.actions';
import {
  allowCameraInteraction,
  deselectParts,
  disableSurfaceCollision,
  enableSurfaceCollision,
  hideCameraGizmo,
  hideLineRenderer,
  hideStepActions,
  hideToolbar,
  hideTransformGizmo,
  hideViewportControls,
  moveCameraGizmo,
  moveCameraToSelectedStep,
  preventCameraInteraction,
  selectParts,
  selectPartsInSelectedStep,
  showCameraGizmo,
  showLineRenderer,
  showStepActions,
  showToolbar,
  showTransformGizmo,
  showViewportControls,
} from './editor.actions';
import {
  hideOtherPartsInSteps,
  hidePartsInSelectedStepAndFollowing,
  hidePartsInSteps,
  highlightParts,
  movePartsInPreviousStepsToAssembledPosition,
  movePartsInSelectedStepToDisassembledPosition,
  movePartsToDisassembledPosition,
  movePartsToStepStartPosition,
  restoreTransparency,
  setPartsToStartPosition,
  setPartsTransparent,
  setTransparencyOff,
  showPartsInSelectedAndPreviousSteps,
  showSelectedParts,
  unhighlightParts,
  updateSelectionBounds,
} from './model.actions';
import { appendStep, createStep, deselectStep, selectStep } from './step.actions';
import { isEditable, isNotFirstStep, isStepListNotEmpty, lastPartsGetDeselected } from './transition.guards';
import { EditorEvent } from './types/machine.types';

export const editorViewMachine = setup({
  types: {
    context: {},
    events: {} as EditorEvent,
  },
  actions: {
    activateIsAnimating,
    allowCameraInteraction,
    appendStep,
    cacheCameraTransform,
    createAnimations,
    createStep,
    deactivateIsAnimating,
    deselectParts,
    deselectStep,
    disableSurfaceCollision,
    enableSurfaceCollision,
    hideAllAnnotations,
    unhighlightAllAnnotations,
    hideAnnotationsInPlayingSequence,
    hideAnnotationsInSelectedStep,
    hideCameraGizmo,
    hideLineRenderer,
    hideOtherPartsInSteps,
    hidePartsInSelectedStepAndFollowing,
    hidePartsInSteps,
    hideStepActions,
    hideToolbar,
    hideTransformGizmo,
    hideViewportControls,
    highlightParts,
    moveCameraGizmo,
    moveCameraToSelectedStep,
    movePartsInPreviousStepsToAssembledPosition,
    movePartsInSelectedStepToDisassembledPosition,
    movePartsToDisassembledPosition,
    movePartsToStepStartPosition,
    nextClip,
    pauseAnimation,
    playAnimation,
    preventCameraInteraction,
    previousClip,
    restoreCameraTransform,
    restoreTransparency,
    resumeAnimation,
    selectParts,
    selectPartsInSelectedStep,
    selectStep,
    setPartsToStartPosition,
    setPartsTransparent,
    setTransparencyOff,
    showAnnotationsInPlayingSequence,
    showAnnotationsInSelectedStep,
    showCameraGizmo,
    showLineRenderer,
    showPartsInSelectedAndPreviousSteps,
    showSelectedParts,
    showStepActions,
    showToolbar,
    showTransformGizmo,
    showViewportControls,
    skipBackward,
    skipForward,
    stopAnimation,
    stopEditingAnnotations,
    unhighlightAllSteps,
    unhighlightParts,
    updateSelectionBounds,
  },
  guards: {
    isNotEditingAnnotation,
    isNotFirstStep,
    isStepListNotEmpty,
    lastPartsGetDeselected,
    isEditable,
  },
}).createMachine({
  id: 'editor-view-machine',
  initial: 'ready',
  context: {},
  states: {
    ready: {
      entry: [
        'unhighlightParts',
        'hideTransformGizmo',
        'hideCameraGizmo',
        'hideLineRenderer',
        'hidePartsInSteps',
        'hideStepActions',
        'deselectParts',
        'deselectStep',
        'allowCameraInteraction',
        'showViewportControls',
        'updateSelectionBounds',
        'hideToolbar',
        'movePartsToDisassembledPosition',
        'hideAllAnnotations',
      ],
      on: {
        SELECT_PARTS: [
          {
            target: 'partsSelected',
            actions: ['selectParts'],
          },
        ],
        PLAY: { target: 'animating', guard: 'isStepListNotEmpty' },
        ADVANCE: { target: 'animating.paused', guard: 'isStepListNotEmpty' },
        SELECT_STEP: [
          {
            target: 'stepSelected',
            actions: ['selectStep'],
          },
        ],
      },
    },
    animating: {
      entry: [
        /*
          'cacheCameraTransform',
          'disableSurfaceCollision',*/
        'unhighlightParts',
        'createAnimations',
        /*
          'deselectParts',
          */
        'unhighlightAllAnnotations',
        'hideLineRenderer',
        'hideTransformGizmo',
        'hideCameraGizmo',
        //'hidePartsInSelectedStepAndFollowing',
        'movePartsInPreviousStepsToAssembledPosition',
        'hideStepActions',
        //'preventCameraInteraction',
        'hideViewportControls',
        'setTransparencyOff',
        //'setPartsTransparent',
        'movePartsToDisassembledPosition',
        'hideToolbar',
        'activateIsAnimating',
        'playAnimation',
      ],
      exit: [
        'stopAnimation',
        'restoreTransparency',
        'setPartsToStartPosition',
        'unhighlightAllSteps',
        'enableSurfaceCollision',
        'restoreCameraTransform',
        'deactivateIsAnimating',
      ],
      on: {
        STOP: { target: 'ready' },
        RESET: { target: 'ready' },
      },
      /* Playback substate */
      initial: 'playing',
      states: {
        playing: {
          entry: ['hideAllAnnotations', 'showAnnotationsInPlayingSequence'],
          on: {
            PAUSE: { target: 'paused' },
            NEXT: {
              target: 'playing',
              reenter: true,
            },
            PREVIOUS: {
              target: 'playing',
              reenter: true,
              guard: 'isNotFirstStep',
              actions: ['previousClip'],
            },
            SKIP_FORWARD: {
              target: 'playing',
              reenter: true,
              actions: ['nextClip'],
            },
            SKIP_BACKWARD: {
              target: 'playing',
              reenter: true,
              actions: ['previousClip'],
            },
          },
        },
        paused: {
          entry: ['pauseAnimation'],
          on: {
            PLAY: { target: 'playing', guard: 'isStepListNotEmpty', actions: ['resumeAnimation'] },
            SKIP_FORWARD: {
              target: 'paused',
              reenter: true,
              actions: ['nextClip'],
            },
            SKIP_BACKWARD: {
              target: 'paused',
              reenter: true,
              actions: ['previousClip'],
            },
          },
        },
      },
      /* End Playback substate */
    },
    stepSelected: {
      entry: [
        'hideAllAnnotations',
        'unhighlightParts',
        'deselectParts',
        //'selectStep',
        'moveCameraGizmo',
        'showCameraGizmo',
        'showStepActions',
        'hidePartsInSteps',
        'movePartsInPreviousStepsToAssembledPosition',
        'movePartsInSelectedStepToDisassembledPosition',
        'showPartsInSelectedAndPreviousSteps',
        'selectPartsInSelectedStep',
        'highlightParts',
        'updateSelectionBounds',
        'showLineRenderer',
        'showToolbar',
        'showAnnotationsInSelectedStep',
        'moveCameraToSelectedStep',
      ],
      exit: ['stopEditingAnnotations'],
      on: {
        DESELECT: [
          { target: 'ready', guard: 'isNotEditingAnnotation' },
          {
            target: 'stepSelected',
            reenter: true,
            actions: 'stopEditingAnnotations',
          },
        ],
        DELETE_STEP: {
          target: 'ready',
        },
        SELECT_STEP: [
          {
            target: 'stepSelected',
            reenter: true,
            actions: ['selectStep'],
          },
        ],
        SELECT_PARTS: [
          {
            target: 'partsSelected',
            reenter: true,
            actions: ['unhighlightParts', 'selectParts'],
          },
        ],
        APPEND_STEP: {
          target: 'stepSelected',
          reenter: true,
          guard: 'isEditable',
          actions: ['appendStep'],
        },
        PLAY: { target: 'animating', guard: 'isStepListNotEmpty' },
        CHANGE_STEP_PARTS: {
          target: 'stepPartsChanged',
          guard: 'isEditable',
        },
        RESET: { target: 'ready' },
      },
    },
    stepPartsChanged: {
      entry: [
        'unhighlightParts',
        'deselectParts',
        'movePartsInPreviousStepsToAssembledPosition',
        'movePartsInSelectedStepToDisassembledPosition',
        'showPartsInSelectedAndPreviousSteps',
        'selectPartsInSelectedStep',
        'highlightParts',
        'updateSelectionBounds',
      ],
      on: {
        DESELECT: [
          { target: 'ready', guard: 'isNotEditingAnnotation' },
          { target: 'stepSelected', actions: 'stopEditingAnnotations' },
        ],
        SELECT_STEP: [
          {
            target: 'stepSelected',
            actions: ['selectStep'],
          },
        ],
        SELECT_PARTS: [
          {
            target: 'partsSelected',
            reenter: true,
            actions: ['unhighlightParts', 'selectParts'],
          },
        ],
        CHANGE_STEP_PARTS: {
          target: 'stepPartsChanged',
          reenter: true,
          guard: 'isEditable',
        },
        APPEND_STEP: {
          target: 'stepSelected',
          actions: ['appendStep'],
          guard: 'isEditable',
        },
        PLAY: { target: 'animating', guard: 'isStepListNotEmpty' },
        RESET: { target: 'ready' },
      },
    },
    partsSelected: {
      entry: [
        'hideAllAnnotations',
        'unhighlightParts',
        'deselectStep',
        'movePartsToDisassembledPosition',
        'hideCameraGizmo',
        'hideOtherPartsInSteps',
        'showSelectedParts',
        'updateSelectionBounds',
        'highlightParts',
        'showToolbar',
      ],
      on: {
        DESELECT: { target: 'ready' },
        SELECT_PARTS: [
          {
            target: 'partsSelected',
            reenter: true,
            actions: ['unhighlightParts', 'selectParts'],
          },
        ],
        CHANGE_STEP_PARTS: {
          target: 'stepPartsChanged',
          guard: 'isEditable',
        },
        DESELECT_PARTS: [
          {
            target: 'ready',
            guard: 'lastPartsGetDeselected',
            actions: ['unhighlightParts', 'selectParts'],
          },
          {
            target: 'partsSelected',
            reenter: true,
            actions: ['unhighlightParts', 'selectParts'],
          },
        ],
        CREATE_STEP: {
          target: 'stepSelected',
          actions: ['createStep', 'selectStep'],
        },
        SELECT_STEP: [
          {
            target: 'stepSelected',
            actions: ['selectStep'],
          },
        ],
        PLAY: { target: 'animating', guard: 'isStepListNotEmpty' },
        RESET: { target: 'ready' },
      },
    },
  },
});
