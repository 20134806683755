import { updateStepData } from '@assemblio/frontend/data-access';
import { Projection, Step, StepData, StepType } from '@assemblio/type/step';
import { CreateStepDto } from '@assemblio/shared/dtos';
import { ThreeTransform, Transform } from '@assemblio/type/3d';
import { Assembly, Part } from '@assemblio/type/input';
import { notifications } from '@mantine/notifications';
import produce, { produceWithPatches } from 'immer';
import _ from 'lodash';
import { Box3, Matrix4, OrthographicCamera, Quaternion, Quaternion as ThreeQuaternion, Vector3 } from 'three';
import { v4 as uuidv4 } from 'uuid';
import { ModelController, StepController, StepGroupController } from '..';
import {
  dataToQuaternion,
  dataToVector3,
  matrixToTransform,
  quaternionToData,
  transformToMatrix,
  vector3ToData,
} from '../../helper';
import { asArray } from '../../helper/array.transform';
import { StepIndex } from '../../indexes/StepIndex';
import { Sequence, SequenceStore, useProjectStore, useSequenceStore, useUIStore } from '../../stores';
import { AnnotationController } from '../AnnotationController';
import { ImperativeModelController } from '../ImperativeModelController';
import { MachineController } from '../MachineController';
import { ProjectController } from '../ProjectController';
import { SequenceController } from '../SequenceController';
import { UndoRedoController } from '../UndoRedoController';
import {
  getAllStepsFlat,
  getNextStepByIndex,
  getNextStepForGltfIndexInDisassemblyOrder,
  getPreviousStepByIndex,
  getSelectedAndPreviousStepsInDisassemblyOrder,
  getStepDisassemblyOrderById,
  getStepsByGltfIndex,
} from './StepAccessController';
import { setStepData } from './StepPropertiesController';
import { sanitizeTransform } from './helper';

/*
/ Implementations of updatePathAtByDelta, removePathAt and appendStep are using map.set()
/ because of an Issue with Immer that leads to faulty patches when modifying map entries inside of produce
/ See: https://github.com/immerjs/immer/issues/952
*/

export const createEmptyStep = (camera: OrthographicCamera, stepGroupId: string): Step => {
  const stepName = 'Empty Step';
  const instructionId = useProjectStore.getState().instructionId;

  return {
    id: uuidv4(),
    instructionId: instructionId,
    name: stepName,
    type: StepType.assembly,
    cameraSettings: {
      near: camera.near,
      projection: Projection.ORTHOGRAPHIC,
      far: camera.far,
      zoom: camera.zoom,
      distance: camera.distance,
      transform: {
        rotation: {
          x: camera.quaternion.x,
          y: camera.quaternion.y,
          z: camera.quaternion.z,
          w: camera.quaternion.w,
        },
        position: camera.position.clone(),
        pivot: { x: 0, y: 0, z: 0 },
      },
    },
    stepGroupId: stepGroupId,
    animationSpeed: 1,
    mtm: 1,
    data: { path: [], parts: [] },
    annotations: new Array<string>(),
    playWithAbove: false,
  } as Step;
};

export const createStep = (
  offsets: Map<number, Matrix4>,
  transforms: Array<ThreeTransform>,
  camera: OrthographicCamera,
  type: StepType
): Step => {
  const stepGroupId = SequenceController.getCurrentStepGroup().id;
  const step = createEmptyStep(camera, stepGroupId);
  const firstTransform = transforms.at(0);
  const lastTransform = transforms.at(-1);
  if (firstTransform && lastTransform) {
    const firstMatrix = new Matrix4().compose(firstTransform.position, firstTransform.rotation, new Vector3(1, 1, 1));
    const lastMatrix = new Matrix4().compose(lastTransform.position, lastTransform.rotation, new Vector3(1, 1, 1));
    step.data = {
      path: transforms,
      parts: Array.from(offsets.entries()).map(([gltfIndex, offsetMatrix]) => {
        const startVector = new Vector3(),
          startQuaternion = new Quaternion(),
          _scale = new Vector3();
        const endVector = new Vector3(),
          endQuaternion = new Quaternion();
        firstMatrix.clone().multiply(offsetMatrix).decompose(startVector, startQuaternion, _scale);
        lastMatrix.clone().multiply(offsetMatrix).decompose(endVector, endQuaternion, _scale);
        return {
          partGltfIndex: gltfIndex,
          start: {
            position: startVector,
            rotation: startQuaternion,
          },
          end: {
            position: endVector,
            rotation: endQuaternion,
          },
        };
      }),
    };
  }
  step.type = type;
  step.name = generateStepName(Array.from(offsets.keys()));

  return step;
};

export const addStep = (step: Step): Step | null => {
  const stepGroupIndex = StepGroupController.getStepGroupDisassemblyOrderById(step.stepGroupId);
  const instructionId = useProjectStore.getState().instructionId;

  let index: number | undefined;
  const [nextState, patches, inversePatches] = produceWithPatches(
    useSequenceStore.getState(),
    (state: SequenceStore) => {
      index = state.stepGroups[stepGroupIndex].steps.push(step) - 1;
    }
  );
  useSequenceStore.setState(nextState);
  UndoRedoController.createPatchAndVersion(
    useSequenceStore,
    `Added step: "${step.name}"`,
    { type: 'add-step', data: { stepId: step.id } },
    nextState,
    patches,
    inversePatches
  );
  if (index !== undefined) {
    StepIndex.syncStep(step, {
      stepGroupIndex: stepGroupIndex,
      stepIndex: index,
    });
    return step;
  } else {
    console.warn('Index is undefined');
    return null;
  }
};

//TODO check if objects can be modified without referencing the state
export const removeStep = (stepId: string): void => {
  const step = getStep(stepId);
  if (step) {
    const index = getStepDisassemblyOrderById(stepId);
    let stepNameToBeDeleted = '';
    const [nextState, patches, inversePatches] = produceWithPatches(
      useSequenceStore.getState(),
      (state: SequenceStore) => {
        stepNameToBeDeleted = state.stepGroups[index.stepGroupIndex].steps[index.stepIndex].name;
        state.stepGroups[index.stepGroupIndex].steps.splice(index.stepIndex, 1);
      }
    );

    useSequenceStore.setState(nextState);
    UndoRedoController.createPatchAndVersion(
      useSequenceStore,
      `Removed step ${stepNameToBeDeleted}`,
      { type: 'remove-step', data: { stepId: step.id } },
      nextState,
      patches,
      inversePatches
    );

    StepIndex.desyncStep(step);
    step.annotations.forEach((annotationId) => {
      AnnotationController.disassociateStepAndAnnotation(annotationId, step.id);
    });

    step.data && step.data.parts.forEach((part) => ModelController.movePartByProgress(part.partGltfIndex, 'assembled'));

    MachineController.deleteStep();
  }
};

export const movePartsInSelectedStepToDisassembledPosition = () => {
  const steps = getSelectedAndPreviousStepsInDisassemblyOrder();
  if (steps) {
    steps.forEach((step) => {
      step.data &&
        step.data.parts.forEach((part) => {
          ModelController.movePartToStepTransform(part.partGltfIndex, step);
        });
    });
  }
};

export const canStepBeDeleted = (stepId: string) => {
  const step = getStep(stepId);
  if (step) {
    const canBeDeleted = !step.data.parts.some((part) => {
      return getNextStepForGltfIndexInDisassemblyOrder(part.partGltfIndex, stepId) !== undefined;
    });
    return canBeDeleted;
  }
  return false;
};

export const getFlatIndexForStep = (stepId: string) => {
  return getAllStepsFlat().findIndex((step) => step.id === stepId);
};

export const canStepBeMoved = (from: { groupId: string; index: number }, to: { groupId: string; index: number }) => {
  const fromStep = StepGroupController.getStepByIndex(from.groupId, from.index);
  if (!fromStep) return false;

  const intermediateSteps = StepGroupController.getStepsBetween(from, to);

  return intermediateSteps.every((intermediateStep) => {
    return intermediateStep.data.parts.every((part) => {
      return fromStep.data.parts.find((other) => other.partGltfIndex === part.partGltfIndex) === undefined;
    });
  });
};

export const reorder = (
  source: { groupId: string; index: number },
  destination: { groupId: string; index: number }
): {
  movedStepId: string | undefined;
  newPrevStepId: string | undefined | null;
} => {
  let movedStepId: string | undefined;
  let newPrevStepId: string | undefined | null;

  useSequenceStore.setState(
    produce<Sequence>((state: Sequence) => {
      const sourceGroup = state.stepGroups.find((g) => g.id === source.groupId);

      // moving to same list
      if (source.groupId === destination.groupId) {
        const steps = sourceGroup!.steps;
        const [removed] = steps.splice(source.index, 1);
        steps.splice(destination.index, 0, removed);

        movedStepId = removed.id;
        newPrevStepId = destination.index > 0 ? steps[destination.index - 1].id : null;
      } else {
        const destinationGroup = state.stepGroups.find((g) => g.id === destination.groupId);
        const target = sourceGroup?.steps[source.index];

        if (target) movedStepId = target.id;
        const steps = sourceGroup!.steps;
        steps.splice(source.index, 1);
        destinationGroup!.steps.splice(destination.index, 0, target!);
        destinationGroup!.steps[destination.index].stepGroupId = destinationGroup!.id;
        newPrevStepId = destination.index > 0 ? destinationGroup!.steps[destination.index - 1].id : null;
      }
    })
  );

  const sourceGroupIndex = StepIndex.getStepGroupIndex(source.groupId);
  const destinationGroupIndex = StepIndex.getStepGroupIndex(destination.groupId);
  StepIndex.updateStepMap(movedStepId!, sourceGroupIndex, destinationGroupIndex, destination.index);

  return { movedStepId, newPrevStepId };
};

export const getStep = (stepId: string): Step | undefined => {
  return StepIndex.getStep(stepId);
};

export const getSelectedStep = (): Step | undefined => {
  const stepId = useUIStore.getState().selectedStep?.id;

  if (stepId) {
    return getStep(stepId);
  }
  return undefined;
};

export const getSelectedSegment = (): { index: number; transform: Transform } | undefined => {
  const stepId = useUIStore.getState().selectedStep?.id;
  if (stepId) {
    const segmentIndex = useUIStore.getState().selectedPathSegmentMap.get(stepId);
    const step = getStep(stepId);
    if (step !== undefined && segmentIndex !== undefined) {
      const transform = step.data.path.at(segmentIndex);
      if (transform) {
        return {
          index: segmentIndex,
          transform,
        };
      }
    }
  }
  return;
};

export const getSelectedStepId = (): string | undefined => {
  return useUIStore.getState().selectedStep?.id;
};

export const getStepsLength = (stepGroupIndex: number): number => {
  return useSequenceStore.getState().stepGroups[stepGroupIndex].steps.length;
};

export const getStepsCount = (): number => {
  let stepCount = 0;
  useSequenceStore.getState().stepGroups.forEach((stepGroup) => {
    stepCount = stepCount + stepGroup.steps.length;
  });
  return stepCount;
};

export const selectPreviousStep = (): void => {
  const selectedStep = useUIStore.getState().selectedStep;

  if (selectedStep && selectedStep.index) {
    const targetStep = getPreviousStepByIndex(selectedStep.index, true);
    if (targetStep) {
      MachineController.selectStep(targetStep);
    }
  }
};

export const selectNextStep = (): void => {
  const selectedStep = useUIStore.getState().selectedStep;

  if (selectedStep && selectedStep.index) {
    const targetStep = getNextStepByIndex(selectedStep.index, true);
    if (targetStep) {
      MachineController.selectStep(targetStep);
    }
  }
};

export const hasStep = (gltfIndex: number): boolean => {
  return StepIndex.hasStep(gltfIndex);
};

export const updateSegmentTransform = (stepId: string, segmentIndex: number, transform: Transform) => {
  const { stepGroupIndex, stepIndex } = getStepDisassemblyOrderById(stepId);
  const currentData = useSequenceStore.getState().stepGroups[stepGroupIndex].steps[stepIndex].data;
  const [nextState, patches, inversePatches] = produceWithPatches(
    useSequenceStore.getState(),
    (draft: SequenceStore) => {
      const step = draft.stepGroups[stepGroupIndex].steps[stepIndex];
      if (step) {
        step.data.path[segmentIndex] = transform;
        if (segmentIndex === step.data.path.length - 1) {
          step.data.parts.forEach((part) => {
            const model = ImperativeModelController.getModel(part.partGltfIndex);
            if (model) {
              const position = model.getWorldPosition(new Vector3());
              const quaternion = model.getWorldQuaternion(new ThreeQuaternion());
              part.end = {
                position: vector3ToData(position),
                rotation: quaternionToData(quaternion),
              };
            }
          });
        }
      }
    }
  );
  useSequenceStore.setState(nextState);
  const newData = useSequenceStore.getState().stepGroups[stepGroupIndex].steps[stepIndex].data;
  StepIndex.syncStep(useSequenceStore.getState().stepGroups[stepGroupIndex].steps[stepIndex], {
    stepGroupIndex,
    stepIndex,
  });
  UndoRedoController.createPatchAndVersion(
    useSequenceStore,
    `Updated Part Segment`,
    { type: 'sync-step-index', data: { stepIndex: stepIndex } },
    nextState,
    patches,
    inversePatches
  );

  return {
    currentData,
    newData,
  };
};

export const canStepBeAppended = (stepId: string) => {
  const step = getStep(stepId);
  if (step) {
    return (
      step.type !== 'alignment' ||
      step.data.parts.every(({ partGltfIndex }) => {
        return getNextStepForGltfIndexInDisassemblyOrder(partGltfIndex, stepId) === undefined;
      })
    );
  }
  return false;
};

export const canStepBeCreated = () => {
  const selectedParts = useUIStore.getState().selectedPartSet;
  const selectedStepGroupReference = SequenceController.getCurrentStepGroup();
  const selectedStepGroup = StepGroupController.getStepGroup(selectedStepGroupReference.id);
  const lastStepGroup = StepGroupController.getLastStepGroup();
  if (selectedStepGroup && lastStepGroup) {
    const stepsBetween = StepGroupController.getStepsBetween(
      { groupId: selectedStepGroup.id, index: selectedStepGroup.steps.length },
      { groupId: lastStepGroup.id, index: lastStepGroup.steps.length }
    );
    return stepsBetween.every(
      (step) => step.data.parts.find((part) => selectedParts.has(part.partGltfIndex)) === undefined
    );
  }
  return false;
};

export const appendStep = (stepId: string, transform: Transform) => {
  const { stepGroupIndex, stepIndex } = getStepDisassemblyOrderById(stepId);
  const currentData = useSequenceStore.getState().stepGroups[stepGroupIndex].steps[stepIndex].data;
  const [nextState, patches, inversePatches] = produceWithPatches(
    useSequenceStore.getState(),
    (draft: SequenceStore) => {
      const step = draft.stepGroups[stepGroupIndex].steps[stepIndex];
      if (step) {
        step.data.path.push({
          position: dataToVector3(transform.position),
          rotation: dataToQuaternion(transform.rotation),
        });
        step.data.parts.forEach((part) => {
          const model = ImperativeModelController.getModel(part.partGltfIndex);
          if (model) {
            const position = model.getWorldPosition(new Vector3());
            const quaternion = model.getWorldQuaternion(new ThreeQuaternion());
            part.end = {
              position: vector3ToData(position),
              rotation: quaternionToData(quaternion),
            };
          }
        });
      }
    }
  );
  useSequenceStore.setState(nextState);
  const newData = useSequenceStore.getState().stepGroups[stepGroupIndex].steps[stepIndex].data;
  StepIndex.syncStep(useSequenceStore.getState().stepGroups[stepGroupIndex].steps[stepIndex], {
    stepGroupIndex,
    stepIndex,
  });
  UndoRedoController.createPatchAndVersion(
    useSequenceStore,
    `Added new part segment`,
    { type: 'sync-step-index', data: { stepIndex: stepIndex } },
    nextState,
    patches,
    inversePatches
  );

  return {
    currentData,
    newData,
  };
};

export const getPivotOffset = (pivotTransform: Transform, transform: Transform) => {
  const pathMatrix = transformToMatrix(pivotTransform).invert();
  const { position, rotation } = transform;
  return pathMatrix.multiply(
    new Matrix4().compose(dataToVector3(position), dataToQuaternion(rotation), new Vector3(1, 1, 1))
  );
};

export const addPartToStep = (gltfIndex: number, stepId: string) => {
  const step = getStep(stepId);
  const model = ModelController.getModelByGltfIndex(gltfIndex);
  if (step && model) {
    const data = _.cloneDeep(step.data);
    const fallBackCopy = _.cloneDeep(data);

    if (data.path.length === 0) {
      const center = new Box3().expandByObject(model).getCenter(new Vector3());
      data.path.push(
        matrixToTransform(new Matrix4().compose(center, new ThreeQuaternion(0, 0, 0, 1), new Vector3(1, 1, 1)))
      );
    }
    const startPath = data.path.at(0);
    const endPath = data.path.at(-1);

    if (!startPath || !endPath) return;
    const startTransform = matrixToTransform(model.matrix);
    const pivotOffset = getPivotOffset(startPath, startTransform);
    const endMatrix = transformToMatrix(endPath);
    const endTransform = matrixToTransform(endMatrix.multiply(pivotOffset));
    const partData = {
      partGltfIndex: gltfIndex,
      start: startTransform,
      end: endTransform,
    };
    data.parts.push(partData);

    setStepData(stepId, data);
    MachineController.changeStepParts(step);
    StepIndex.associateModelAndStep(gltfIndex, stepId);

    updateStepData({
      id: step.id,
      data: sanitizeStepData(data),
    }).catch((_error) => {
      notifications.show({
        id: 'update-step-data',
        message: 'Could not update step data',
        color: 'red',
      });
      // TODO Reset Part placement correctly
      //data.parts = data.parts.filter((value) => value.partGltfIndex !== gltfIndex);

      setStepData(stepId, fallBackCopy);
      StepIndex.disassociateModelAndStep(gltfIndex, stepId);
      MachineController.changeStepParts(step);
    });
  }
};

export const canModifyStep = (gltfIndex: number, stepId: string) => {
  return StepController.getNextStepForGltfIndexInDisassemblyOrder(gltfIndex, stepId) === undefined;
};

export const removePartFromStep = (gltfIndex: number, stepId: string) => {
  const step = getStep(stepId);
  const model = ModelController.getModelByGltfIndex(gltfIndex);
  if (step && step.data && model) {
    const data = _.cloneDeep(step.data);
    const part = data.parts.find((part) => part.partGltfIndex);
    data.parts = data.parts.filter((part) => part.partGltfIndex !== gltfIndex);
    setStepData(stepId, data);
    if (part) {
      ModelController.movePartToTransform(gltfIndex, part.start);
      StepIndex.disassociateModelAndStep(gltfIndex, stepId);
      MachineController.changeStepParts(step);

      updateStepData({
        id: step.id,
        data: sanitizeStepData(data),
      }).catch((_error) => {
        notifications.show({
          id: 'update-step-data',
          message: 'Could not update step data',
          color: 'red',
        });
        // TODO Reset Part placement correctly

        if (part) {
          data.parts.push(part);
          setStepData(stepId, data);
          StepIndex.associateModelAndStep(gltfIndex, stepId);
          MachineController.changeStepParts(step);
        }
      });
    }
  }
};

export const getPartIds = (stepId: string) => {
  return getStep(stepId)?.data.parts.map((step) => step.partGltfIndex);
};

export const stepToDto = (step: Step): CreateStepDto => {
  const stepIndex = getStepDisassemblyOrderById(step.id);
  const prevStep = getPreviousStepByIndex(stepIndex, false);
  return new CreateStepDto(step, prevStep ? prevStep.id : null);
};

export const sanitizeStepData = (data: StepData): StepData => {
  return {
    parts: data.parts.map((part) => {
      return {
        partGltfIndex: part.partGltfIndex,
        start: sanitizeTransform(part.start),
        end: sanitizeTransform(part.end),
      };
    }),
    path: data.path.map((transform) => sanitizeTransform(transform)),
  };
};

export const generateStepName = (partGltfIndices: number | number[]): string => {
  // ToDo: Find a shorter version for the following algorithm
  let stepName = '';
  let parentSet: Set<number> = new Set<number>();
  let assemblySet: Set<number> = new Set<number>();
  const partArray: Array<Part> = new Array<Part>();
  const selectedAssemblies: Array<Assembly> = new Array<Assembly>();

  // Gather all parts that were included in the selection
  // and add their parents to a set
  asArray(partGltfIndices).forEach((gltfIndex) => {
    const part = ProjectController.getPartByGltfIndex(gltfIndex);
    if (part) {
      const parents = ProjectController.getParentGltfIndexes(gltfIndex);
      parentSet = new Set([...parentSet, ...parents]);
      partArray.push(part);
    }
  });

  // Iterate over parent set and check if they are selected
  // If so push them to the selection Array
  parentSet.forEach((gltfIndex) => {
    const assembly = ProjectController.getAssemblyByGltfIndex(gltfIndex);
    if (assembly) {
      const selected = ModelController.isAssemblySelected(assembly);
      if (selected) {
        selectedAssemblies.push(assembly);
        assemblySet = new Set([...assemblySet, ...assembly.assemblies]);
      }
    }
  });

  // Remove parts from the final list that are part of an selected Assembly
  selectedAssemblies.forEach((assembly) => {
    assembly.parts.forEach((assemblyPart) => {
      _.remove(partArray, (part) => {
        return assemblyPart === ProjectController.getPartIndexByGltfIndex(part.gltfIndex);
      });
    });
  });

  // Remove assemblies that are part of another selected assembly
  const filteredAssemblies = selectedAssemblies.filter((assembly) => {
    const index = ProjectController.getAssemblyIndexByGltfIndex(assembly.gltfIndex);
    if (index !== undefined) {
      return !assemblySet.has(index);
    } else return false;
  });

  let shortName = true;

  // Add remaining assemblies to the name
  // Stop if the name is already too long
  filteredAssemblies.every((assembly) => {
    const name = ModelController.getAssemblyNameOverride(assembly);
    if (stepName.concat(`${name} `).length <= 246) {
      stepName = stepName.concat(`${name}, `);
    } else {
      shortName = false;
    }
    return shortName;
  });

  // Add remaining parts to the name which are not a child of a selected assembly
  // Stop if the name is already too long
  partArray.every((part) => {
    if (!shortName) return false;
    const name = ModelController.getPartNameOverride(part);
    if (stepName.concat(`${name} `).length <= 246) {
      stepName = stepName.concat(`${name}, `);
    } else {
      shortName = false;
    }
    return shortName;
  });

  stepName = stepName.slice(0, -2); // Remove trailing comma and whitespace

  if (!shortName) stepName = stepName.concat(' and more');

  if (stepName.length > 0) return stepName;
  else return `Unnamed Step`;
};

export const isPartDisassembled = (gltfIndex: number) => {
  return getStepsByGltfIndex(gltfIndex).some((step) => step.type === 'assembly');
};

export const isAssemblyDisassembled = (gltfIndex: number) => {
  const parts = ProjectController.getPartsGltfIndicesOfAssemblyByGltfIndex(gltfIndex, true);
  return parts.every((gltfIndex) => isPartDisassembled(gltfIndex));
};
