import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class RenameGroupDto {
  @ApiProperty({
    description: 'Group name',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;
}

export const RenameGroupSchema = generateClassValidationObject(RenameGroupDto);
