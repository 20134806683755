import { useStepDeleteText } from '@assemblio/frontend/data-access';
import { StepController } from '@assemblio/frontend/stores';
import { Step } from '@assemblio/type/step';
import { ActionIcon, Button, Modal, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import classes from '../Styles/StepText.module.scss';
import { StepTextEditor } from './StepTextEditor';
import { IconTrash } from '@tabler/icons-react';
import { StepButtonInput } from './StepButtonInput';

interface Props {
  step: Step;
  selected: boolean;
  disabled?: boolean;
}

export const StepTextDisplay = ({ step, selected, disabled }: Props) => {
  const [opened, { open, close }] = useDisclosure(false);
  const deleteStepTextMutation = useStepDeleteText();

  const stepTextExists = step.text !== null && step.text !== undefined;

  const deleteText = () => {
    deleteStepTextMutation.mutate(step.id, {
      onSuccess: () => {
        StepController.setStepText(step.id, null);
        notifications.show({
          id: 'delete-step-text-success',
          message: 'Text has been deleted',
          color: 'green',
        });
      },
      onError: () => {
        notifications.show({
          id: 'save-step-text-error',
          message: 'Text could not be deleted',
          color: 'red',
        });
      },
    });
  };

  return (
    <>
      <StepButtonInput label={'Text'}>
        {stepTextExists ? (
          <ActionIcon.Group h={'2rem'} w={'100%'}>
            <Tooltip openDelay={500} label={'Edit Text'}>
              <Button
                w={'70%'}
                h={'2rem'}
                variant={'default'}
                classNames={{
                  root: classes.action_icon,
                }}
                disabled={disabled || deleteStepTextMutation.isLoading}
                onClick={open}
                size="xs"
              >
                Edit
              </Button>
            </Tooltip>
            <ActionIcon
              w={'30%'}
              h={'2rem'}
              variant={'default'}
              classNames={{
                root: classes.action_icon,
              }}
              onClick={deleteText}
              disabled={disabled}
              loading={deleteStepTextMutation.isLoading}
              size="xs"
            >
              <IconTrash style={{ width: '50%', height: '50%' }} />
            </ActionIcon>
          </ActionIcon.Group>
        ) : (
          <Button
            classNames={{
              root: classes.create_button,
            }}
            onClick={open}
            disabled={disabled}
            radius={'sm'}
            h={'2rem'}
            size="xs"
            fullWidth
          >
            Create step text
          </Button>
        )}
      </StepButtonInput>
      <Modal
        opened={opened}
        onClose={close}
        title={stepTextExists ? 'Edit step text' : 'Create step text'}
        centered
        size={'50vw'}
      >
        <StepTextEditor step={step} />
      </Modal>
    </>
  );
};
