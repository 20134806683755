import {
  ApproveRevisionDto,
  CreateRevisionDto,
  RejectRevisionDto,
  RemoveRevisionDto,
  RequestChangeDto,
  RevisionDto,
  RevisionHistoryDto,
  UpdateReviewerDto,
} from '@assemblio/shared/dtos';
import { axiosHandler } from '@assemblio/frontend/data-access';

export const requestProductChange = async (data: RequestChangeDto) => {
  return axiosHandler('post', `v1/products/${data.productId}/revisions/request-changes`, { data });
};

export const rejectProductRevision = async (data: RejectRevisionDto) => {
  return axiosHandler('post', `v1/products/${data.productId}/revisions/reject`, { data });
};

export const approveProductRevision = (data: ApproveRevisionDto) => {
  return axiosHandler('post', `v1/products/${data.productId}/revisions/approve`, { data });
};

export const setProductReviewer = (data: UpdateReviewerDto) => {
  return axiosHandler('patch', `v1/revisions/reviewer`, { data });
};

export const createProductRevision = async (data: CreateRevisionDto) => {
  return axiosHandler('post', `v1/revisions`, { data });
};

export const removeProductRevision = async (data: RemoveRevisionDto) => {
  return axiosHandler('delete', `v1/products/${data.id}/revisions/${data.revisionId}`);
};

export const fetchProductRevisions = async (productId: string | undefined): Promise<RevisionHistoryDto[]> => {
  return typeof productId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `v1/products/${productId}/revisions`);
};

export const fetchLatestProductRevision = (instructionId: string | undefined): Promise<RevisionDto> => {
  return typeof instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `v1/products/${instructionId}/revision/latest`);
};
