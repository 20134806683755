import { useProductOwnerChange } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { useModalContext } from '@assemblio/frontend/components';
import { useExplorerRouteParams } from '../ExplorerRouteParams.hook';
import { SelectUserModalProps } from '../../modals';

export const useChangeProductOwner = () => {
  const changeOwnerMutation = useProductOwnerChange();
  const { openModal } = useModalContext();
  const { productId } = useExplorerRouteParams();

  const changeProductOwner = (options?: SelectUserModalProps) => {
    openModal('select-user-modal', {
      title: 'Change Owner',
      userRoleLabel: 'Owner',
      buttonText: 'Change Owner',
      ...options,
      onSubmitUser: handleChangeOwner,
    });
  };

  const handleChangeOwner = (userId: string) => {
    if (!productId) return;
    changeOwnerMutation.mutate(
      {
        productId: productId,
        newOwnerId: userId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'change-owner',
            message: 'Owner has been changed',
          });
        },
      }
    );
  };

  return { changeProductOwner, isLoading: changeOwnerMutation.isLoading };
};
