import { ApiProperty } from '@assemblio/shared/decorators';

export class GroupDto {
  @ApiProperty({
    description: 'Group id',
    required: true,
  })
  id!: string;

  @ApiProperty({
    description: 'Group name',
    required: true,
  })
  name!: string;

  @ApiProperty({
    description: 'Group slug',
    required: true,
  })
  slug!: string;
}
