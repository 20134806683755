import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class UpdateProjectDto {
  @ApiProperty({
    description: 'The name of the project',
    required: false,
  })
  @joiSchemaProperty(Joi.string().optional())
  name?: string;

  // ToDo: check if this creator can be updated?
  @ApiProperty({
    description: 'The creator id',
    required: false,
  })
  @joiSchemaProperty(Joi.string().uuid())
  creatorId?: string;

  @ApiProperty({
    description: 'The thumbnail name',
    required: false,
  })
  @joiSchemaProperty(Joi.string().optional())
  thumbnailPath?: string;

  @ApiProperty({
    description: 'The owner id',
    required: false,
  })
  @joiSchemaProperty(Joi.string().uuid())
  folderId?: string;
}

export const UpdateProjectSchema = generateClassValidationObject(UpdateProjectDto);
