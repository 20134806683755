import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class ApproveRevisionDto {
  @ApiProperty({
    description: 'The id of the product',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  productId!: string;

  @ApiProperty({
    description: 'The id of the revision',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  revisionId!: string;
}

export const approveRevisionSchema = generateClassValidationObject(ApproveRevisionDto);
