import { InstructionState } from '@assemblio/type/instruction';
import { InstructionEditorSettingsDto } from '../instruction';
import { ApiProperty } from '@assemblio/shared/decorators';
import { UserSummaryDto } from '../user';

export class ProductAccessDto {
  @ApiProperty({ description: 'The instruction id', required: true })
  id!: string;

  @ApiProperty({ description: 'The instruction name', required: true })
  name!: string;

  @ApiProperty({ description: 'The instruction state', required: true, enum: InstructionState, type: InstructionState })
  state!: InstructionState;

  @ApiProperty({ description: 'The active instruction version', required: true})
  activeVersion!: number;

  @ApiProperty({ description: 'The editor settings', required: false, type: InstructionEditorSettingsDto })
  editorSettings?: InstructionEditorSettingsDto;

  @ApiProperty({ description: 'The owner of the instruction', required: true })
  ownedBy!: UserSummaryDto;

  @ApiProperty({ description: 'Is the instruction editable', required: true })
  editable!: boolean;

  @ApiProperty({ description: 'Is the instruction viewable', required: true })
  viewable!: boolean;
}
