import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class CreateProjectDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({
    description: 'project name',
    required: true,
  })
  name!: string;

  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({
    description: 'the owner id',
    required: true,
  })
  ownerId!: string;

  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({
    description: 'the folder id',
    required: true,
  })
  folderId!: string;
}

export const CreateProjectSchema = generateClassValidationObject(CreateProjectDto);
