import { StepType } from '@assemblio/type/step';
import { StepCameraSettingsDto, StepCameraSettingsSchema, StepDataDto, StepDataSchema } from './step-props.dto';
import { TextDto, TextSchema } from '../text';
import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class AnnotationToStepDto {
  @ApiProperty({ description: 'Id', required: false })
  @joiSchemaProperty(Joi.string().uuid())
  id?: string;
}

export const AnnotationToStepSchema = generateClassValidationObject(AnnotationToStepDto);

export class StepDto {
  @ApiProperty({ description: 'Id', required: true })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({ description: 'Instruction Id', required: true })
  @joiSchemaProperty(Joi.string().uuid().required())
  instructionId!: string;

  @ApiProperty({ description: 'Group Id', required: true })
  @joiSchemaProperty(Joi.string().uuid().required())
  stepGroupId!: string;

  @ApiProperty({ description: 'Name', required: true })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({ description: 'Next step id', required: true })
  @joiSchemaProperty(Joi.string().uuid())
  next!: string | null;

  @ApiProperty({ description: 'Previous step id', required: true })
  @joiSchemaProperty(Joi.string().uuid())
  prev!: string | null;

  @ApiProperty({ description: 'type', required: true, enum: StepType })
  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(StepType))
      .required()
  )
  type!: StepType;

  @ApiProperty({ description: 'Prev step id', required: true, type: Boolean })
  @joiSchemaProperty(Joi.boolean().required())
  playWithAbove!: boolean;

  @ApiProperty({ description: 'animation speed', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  animationSpeed!: number;

  @ApiProperty({ description: 'MTM', required: true, type: Number })
  @joiSchemaProperty(Joi.number().required())
  mtm!: number;

  @ApiProperty({ description: 'camera settings', required: true, type: StepCameraSettingsDto })
  @joiSchemaProperty(StepCameraSettingsSchema.required())
  cameraSettings!: StepCameraSettingsDto;

  @ApiProperty({ description: 'data', required: true, type: StepDataDto })
  @joiSchemaProperty(StepDataSchema.required())
  data!: StepDataDto;

  @ApiProperty({ description: 'data', required: false, type: TextDto })
  @joiSchemaProperty(TextSchema.allow(null))
  text!: TextDto | null;

  @ApiProperty({ description: 'annotations', required: true, type: [AnnotationToStepDto] })
  @joiSchemaProperty(Joi.array().items(AnnotationToStepSchema).required())
  annotations!: AnnotationToStepDto[];
}

export const StepSchema = generateClassValidationObject(StepDto);
