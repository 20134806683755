import { Badge, BadgeProps } from '@mantine/core';
import classes from './VersionBadge.module.scss';
import { PropsWithChildren } from 'react';
import cx from 'clsx';

interface VersionBadgeProps extends BadgeProps {
  version?: number;
  variant?: 'explorer' | 'editor';
}

export const VersionBadge = ({
  version,
  variant = 'explorer',
  children,
  ...props
}: PropsWithChildren<VersionBadgeProps>) => {
  return (
    <Badge
      className={cx(classes.badge, {
        [classes.badge__explorer]: variant === 'explorer',
        [classes.badge__editor]: variant === 'editor',
      })}
      p={'xs'}
      py={0}
      h={variant === 'editor' ? 'fit-content' : '1.5rem'}
      {...props}
    >
      {typeof version === 'number' ? `V${version}` : children}
    </Badge>
  );
};
