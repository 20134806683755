import React from 'react';
import { Button } from '@mantine/core';

interface EditButtonProps {
  isProductEditable: boolean;
  handleOpenProduct: () => void;
}

export const AccessAssemblyPlanButton: React.FC<EditButtonProps> = ({ isProductEditable, handleOpenProduct }) => {
  return (
    <Button
      size={'compact-md'}
      onClick={handleOpenProduct}
      variant={isProductEditable ? 'primary' : 'filled'}
      color={isProductEditable ? undefined : 'blue'}
    >
      {isProductEditable ? 'Edit' : 'View'} Assembly Plan
    </Button>
  );
};
