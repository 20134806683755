import classes from './TopBar.module.scss';
import { useProjectStore } from '@assemblio/frontend/stores';
import { ActionIcon, Button, Group, Skeleton, Text } from '@mantine/core';
import { ImageMark } from '../ImageMark';
import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useModalContext } from '../ModalManager';
import { ViewportSettingsMenu } from './components/ViewportSettingsMenu';
import { HelpMenu } from './components/HelpMenu/HelpMenu';
import React from 'react';
import { UserSettingsMenu } from './components/UserSettingsMenu';
import { VersionBadge } from '../../../../../explorer/src/lib/layout/Header/components/VersionBadge/VersionBadge';

export const TopBar = () => {
  const { openModal } = useModalContext();
  const { name, state, instructionId, navigatedToComposerFrom, currentVersion } = useProjectStore();
  const navigate = useNavigate();
  // Project Store is initialized with an empty string. If the instructionId is not empty, the products information is loaded.
  const isProductLoaded = instructionId.length > 0;

  const handleGoBack = () => {
    navigatedToComposerFrom ? navigate(navigatedToComposerFrom) : navigate(`/explorer/product/${instructionId}`);
  };

  const handleExport = () => {
    openModal('export-modal', {
      name,
      instructionId,
    });
  };

  return (
    <Group className={classes.container} px={'lg'} align={'center'}>
      <Group>
        <ImageMark />
        <ActionIcon size={'xs'} variant={'transparent'} c={'text-primary'} onClick={handleGoBack}>
          <IconArrowLeft />
        </ActionIcon>
      </Group>
      <Group className={classes.title}>
        {isProductLoaded ? (
          <>
            <Text>{name}</Text>
            <VersionBadge variant={'editor'} version={currentVersion} />
            <Text size="sm" c={'text-secondary'}>
              {state}
            </Text>
          </>
        ) : (
          <Skeleton h={'1rem'} w={'5rem'} />
        )}
      </Group>
      <Group>
        <HelpMenu />
        <UserSettingsMenu />
        <ViewportSettingsMenu disabled={!isProductLoaded} />
        <Button disabled={!isProductLoaded} variant={'primary'} onClick={handleExport}>
          Export
        </Button>
      </Group>
    </Group>
  );
};
