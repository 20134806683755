import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class RequestChangeDto {
  @ApiProperty({
    description: 'The id of the instruction',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  productId!: string;

  @ApiProperty({
    description: 'The id of the revision',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  revisionId!: string;
}

export const requestChangeSchema = generateClassValidationObject(RequestChangeDto);
