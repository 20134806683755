import { ProductContentDto } from '@assemblio/shared/dtos';

import { SyncProfileRelationDto } from '@assemblio/shared/next-types';
import { useBaseProductAction } from './productActions.base';
import { useProductReviewActions } from './productActions.review';
import { useProductSyncActions } from './productActions.sync';
import { useAuthStore } from '@assemblio/frontend/data-access';
import { useUIStore } from '@assemblio/frontend/stores';
import { ProductAction, UserState } from '../types/productActions.types';
import { InstructionState } from '@assemblio/type/instruction';
import { AppRole } from '@assemblio/type/user';

export const useProductActions = (product: ProductContentDto | undefined, syncProfiles?: SyncProfileRelationDto[]) => {
  const { userId: currentUserId, appRole } = useAuthStore((state) => state);
  const isViewer = useUIStore.getState().view === 'viewer';

  const filterProductState = (action: ProductAction): boolean => {
    if (action.allowedProductStates) {
      const allowedStates: (InstructionState | undefined)[] = action.allowedProductStates;
      return allowedStates.includes(product?.state);
    }
    return true;
  };
  const filterUserRole = (action: ProductAction): boolean => {
    if (action.allowedUserRoles) {
      const allowedRoles: (AppRole | undefined)[] = action.allowedUserRoles;
      return allowedRoles.includes(appRole);
    }
    return true;
  };

  const userState: UserState = {
    isOwner: currentUserId === product?.ownedBy.id,
    isReviewer: currentUserId === product?.revision?.reviewer?.id,
    currentUserId,
    isViewer,
  };

  const { productActions: baseActions, isLoading: isBaseActionsLoading } = useBaseProductAction({ product, userState });
  const { productActions: reviewActions, isLoading: isReviewActionsLoading } = useProductReviewActions({
    product,
    userState,
  });
  const { productActions: syncActions } = useProductSyncActions({ product, userState, syncProfiles });

  const sortedActions = [...baseActions, ...reviewActions, ...syncActions].sort((a, b) => {
    return (a.order || 100) - (b.order || 100);
  });

  const filteredActions = sortedActions.filter(filterProductState).filter(filterUserRole);

  return {
    productActions: filteredActions,
    isLoading: isBaseActionsLoading || isReviewActionsLoading,
  };
};
