import React from 'react';
import { Button, ScrollArea, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { VersionItem, VersionItemSkeleton } from './components/VersionItem/VersionItem';
import { useFetchRevisions } from '@assemblio/frontend/data-access';
import classes from './VersionHistoryTab.module.scss';
import { IconViewNotesOn } from '@assemblio/design-system';
import { useRequestProductReview } from '@assemblio/frontend/explorer';

interface VersionHistoryProps {
  productId: string | undefined;
}

const VERSION_HISTORY_SKELETON_COUNT = 4;

export const VersionHistoryTab = ({ productId }: VersionHistoryProps) => {
  const { data, isLoading } = useFetchRevisions(productId);

  const isEmpty = !isLoading && data?.length === 0;
  //TODO: remove mock data once endpoint delivers data
  return (
    <Stack gap={0} px={'xl'} pt={'lg'} h={'100%'}>
      <Title order={3}>Revision history</Title>
      {isEmpty ? (
        <EmptyHistory productId={productId} />
      ) : (
        <Text c={'text-secondary'} pb={'md'}>
          List of revisions on the Product.
        </Text>
      )}
      <ScrollArea className={classes.scroll_wrapper}>
        <Stack gap={0} className="versionTimeline">
          {isLoading
            ? [...Array(VERSION_HISTORY_SKELETON_COUNT)].map((_, index) => <VersionItemSkeleton key={index} />)
            : data?.map((version, _) => (
                <VersionItem key={`${version.productId}-${version.version}`} versionInfo={version} />
              ))}
        </Stack>
      </ScrollArea>
    </Stack>
  );
};

const EmptyHistory = ({ productId }: VersionHistoryProps) => {
  const { requestReview } = useRequestProductReview(productId);
  return (
    <Stack h={'100%'} justify={'center'} align={'center'}>
      {/*TODO: use different icon or visualization*/}
      <ThemeIcon size={100} color={'var(--text-tertiary)'} radius={'xl'}>
        <IconViewNotesOn size={50} />
      </ThemeIcon>
      <Text>There are no revision for this file yet. Start by requesting a review.</Text>
      <Button onClick={requestReview} variant={'primary'}>
        Request Review
      </Button>
    </Stack>
  );
};
