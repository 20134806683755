import { Checkbox, Group, Text, Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

type DocExportSettingsProps = {
  showParts: boolean;
  showResources: boolean;
  onChange: (prop: Record<string, boolean>) => unknown;
};

export const DocExporterSettings = ({ showParts, showResources, onChange }: DocExportSettingsProps) => {
  return (
    <Group w={'100%'} pl={'xl'}>
      <Text size={'sm'}>Material List</Text>
      <Tooltip label="Includes an additonal chapter with tables for parts and/or resources">
        <IconInfoCircle size={'1rem'} />
      </Tooltip>
      <Group w={'100%'} pl={'xl'}>
        <Checkbox size={'sm'} checked={showParts} readOnly onClick={() => onChange({ showParts: !showParts })} />
        <Text size={'sm'}>Include Parts Table</Text>
      </Group>
      <Group w={'100%'} pl={'xl'}>
        <Checkbox
          size={'sm'}
          checked={showResources}
          readOnly
          onClick={() => onChange({ showResources: !showResources })}
        />
        <Text size={'sm'}>Include Resources Table</Text>
      </Group>
    </Group>
  );
};
