import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class UpdateTenantDto {
  @ApiProperty({
    description: 'Tenant name',
    required: true,
  })
  @joiSchemaProperty(Joi.string().optional())
  name?: string;

  @ApiProperty({
    description: 'Tenant expiration date',
    required: false,
  })
  @joiSchemaProperty(Joi.date().optional())
  expirationDate?: Date | null;
}

export const UpdateTenantSchema = generateClassValidationObject(UpdateTenantDto);
