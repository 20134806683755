import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ApproveRevisionDto,
  CreateRevisionDto,
  RequestChangeDto,
  RejectRevisionDto,
  RemoveRevisionDto,
} from '@assemblio/shared/dtos';
import { notifications } from '@mantine/notifications';
import { ChangeProductReviewer } from '../types/instruction.request.types';
import {
  approveProductRevision,
  createProductRevision,
  requestProductChange,
  rejectProductRevision,
  setProductReviewer,
  removeProductRevision,
} from './instruction.review.requests';

export const useCreateProductRevision = () => {
  const qC = useQueryClient();
  return useMutation((data: CreateRevisionDto) => createProductRevision(data), {
    onSuccess: (_, values) => {
      qC.invalidateQueries(['product', values.instructionId]);
      qC.invalidateQueries(['product-revisions', values.instructionId]);
      qC.invalidateQueries(['product-revision', values.instructionId]);
    },
    onError: (_error) => {
      notifications.show({
        id: 'create-product-revision',
        message: 'Could not request review',
        color: 'red',
      });
    },
  });
};

export const useRemoveProductRevision = () => {
  const qC = useQueryClient();
  return useMutation((data: RemoveRevisionDto) => removeProductRevision(data), {
    onSuccess: (_, values) => {
      qC.invalidateQueries(['product', values.id]);
      qC.invalidateQueries(['product-revisions', values.id]);
      qC.invalidateQueries(['product-revision', values.id]);
    },
    onError: (_error) => {
      notifications.show({
        id: 'create-product-revision',
        message: 'Could not request review',
        color: 'red',
      });
    },
  });
};

export const useRequestProductChangesMutation = () => {
  const qC = useQueryClient();

  return useMutation((data: RequestChangeDto) => requestProductChange(data), {
    onSuccess: (_, values) => {
      qC.invalidateQueries(['product', values.productId]);
      qC.invalidateQueries(['product-revisions', values.productId]);
      qC.invalidateQueries(['product-revision', values.productId]);
    },
    onError: (_error) => {
      notifications.show({
        id: 'request-product-changes',
        message: 'Could not request changes',
        color: 'red',
      });
    },
  });
};

export const usePatchProductReviewer = () => {
  const qC = useQueryClient();
  return useMutation((patch: ChangeProductReviewer) => setProductReviewer(patch.data), {
    onSuccess: (_, values) => {
      qC.invalidateQueries(['product', values.productId]);
    },
    onError: () => {
      notifications.show({
        id: 'change-product-reviewer',
        message: 'Could not change reviewer',
        color: 'red',
      });
    },
  });
};

export const useApproveProductRevision = () => {
  const qC = useQueryClient();
  return useMutation((data: ApproveRevisionDto) => approveProductRevision(data), {
    onSuccess: (_, values) => {
      qC.invalidateQueries(['product', values.productId]);
      qC.invalidateQueries(['product-revisions', values.productId]);
      qC.invalidateQueries(['product-revision', values.productId]);
    },
    onError: () => {
      notifications.show({
        id: 'approve-product-revision',
        message: 'Could not set revision to approved',
        color: 'red',
      });
    },
  });
};

export const useRejectProductRevisionMutation = () => {
  const qC = useQueryClient();
  return useMutation((data: RejectRevisionDto) => rejectProductRevision(data), {
    onSuccess: (_, values) => {
      qC.invalidateQueries(['product', values.productId]);
      qC.invalidateQueries(['product-revisions', values.productId]);
      qC.invalidateQueries(['product-revision', values.productId]);
    },
    onError: () => {
      notifications.show({
        id: 'product-revision-changes-needed',
        message: 'Could not set revision to changes needed',
        color: 'red',
      });
    },
  });
};
