import { Center, Group, Skeleton, Stack, Text } from '@mantine/core';
import classes from './VersionItem.module.scss';
import { IconViewNotesOn, Tag } from '@assemblio/design-system';
import { RevisionHistoryDto } from '@assemblio/shared/dtos';
import { ReviewStatus } from '@assemblio/type/revision';
import { ProductVersionType } from '@assemblio/type/instruction';
import { RevisionActionDisplay } from './components/RevisionActionDisplay';

interface VersionItemProps {
  versionInfo: RevisionHistoryDto;
}

export const VersionItem = ({ versionInfo }: VersionItemProps) => {
  const { version, createdAt, state, reviewer, requestee, type, comment } = versionInfo;

  const formatDate = (date: string) => {
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  const primaryUser = state === ReviewStatus.IN_REVIEW ? requestee : reviewer;
  const secondaryUser = state === ReviewStatus.IN_REVIEW ? reviewer : requestee;

  return (
    <Stack>
      <Group wrap={'nowrap'} className={classes.versionInfo} gap={'lg'}>
        <Stack gap={'xs'} align={'center'} className={classes.versionText} pt={'sm'}>
          <Tag variant={type === 'Major' ? 'primary' : 'default'}>
            {type === ProductVersionType.UNSET ? (
              <Center>
                <IconViewNotesOn size={12} />
              </Center>
            ) : (
              `V${version}`
            )}
          </Tag>
          <div className={classes.line} />
        </Stack>
        <Group className={classes.versionItem}>
          <RevisionActionDisplay
            primaryUserName={primaryUser.fullName}
            secondaryUserName={secondaryUser.fullName}
            state={state}
            comment={comment}
          />

          <Group className={classes.versionItem__status} mt={'md'} align={'center'}>
            <Tag state={state} />
            <Text size="sm" className={classes.dateText}>
              {formatDate(createdAt)}
            </Text>
          </Group>
        </Group>
      </Group>
    </Stack>
  );
};

export const VersionItemSkeleton = () => (
  <Stack>
    <Group gap={'lg'} className={classes.versionInfo}>
      <Stack gap={'xs'} align={'center'} className={classes.versionText} pt={'sm'}>
        <Skeleton w={'2.5rem'} h={'1.5rem'} />
        <div className={classes.line} />
      </Stack>

      <Group className={classes.versionItem}>
        <Group className={classes.versionItem__action}>
          <Skeleton w={'3rem'} h={'3rem'} radius={'100%'} />
          <Stack gap={'xs'}>
            <Skeleton w={'5rem'} h={'1rem'} />
            <Skeleton w={'10rem'} h={'1rem'} />
          </Stack>
        </Group>
        <Group className={classes.versionItem__status}>
          <Skeleton w={'5rem'} h={'1.5rem'} />
          <Skeleton w={'2rem'} h={'1.5rem'} />
        </Group>
      </Group>
    </Group>
  </Stack>
);
