import { IconChangeUser } from '@assemblio/design-system';
import { useEffect, useState } from 'react';
import {
  IconCopy,
  IconFileArrowRight,
  IconPencil,
  IconStar,
  IconStarFilled,
  IconTrashFilled,
} from '@tabler/icons-react';
import { useChangeProductOwner, useDeleteProduct, useRenameProduct, useToggleFavorite } from '../../../../../hooks';
import { useModalContext } from '@assemblio/frontend/components';
import { ProductAction, ProductActionHookInput } from '../types/productActions.types';
import { InstructionState } from '@assemblio/type/instruction';

export const useBaseProductAction = ({ product, userState }: ProductActionHookInput) => {
  const [isLoading, setIsLoading] = useState(false);

  const { isOwner, isViewer } = userState;

  const { openModal } = useModalContext();
  const { renameProduct, isLoading: isRenameMutationLoading } = useRenameProduct();
  const { deleteProduct, isLoading: isDeleteMutationLoading } = useDeleteProduct();
  const { changeProductOwner, isLoading: isChangeOwnerLoading } = useChangeProductOwner();
  const { toggleFavorite, isLoading: isFavoriteLoading } = useToggleFavorite();

  const reviewerId = product?.revision?.reviewer.id;

  const onDelete = () => {
    if (!product) return;
    deleteProduct(product.name, product.id, `/v2/explorer/project/${product.project.id}`);
  };

  const onRename = () => {
    if (!product) return;
    renameProduct(product.name, product.id);
  };

  const onToggleFavorite = () => {
    if (!product) return;
    toggleFavorite(product, product.project.id);
  };

  const openExportModal = () => {
    if (!product) return;
    openModal('export-modal', {
      instructionId: product.id,
      name: product.name,
    });
  };

  const actions: ProductAction[] = [
    {
      label: 'Rename',
      Icon: IconPencil,
      onClick: onRename,
      show: isOwner && !isViewer,
    },
    {
      label: 'Duplicate',
      Icon: IconCopy,
      onClick: () => null,
      show: false,
    },
    {
      label: `${product?.isFavorite ? 'Remove from' : 'Add to'} Favorites`,
      Icon: product?.isFavorite ? IconStarFilled : IconStar,
      onClick: onToggleFavorite,
      order: 1,
    },
    {
      label: 'Export',
      Icon: IconFileArrowRight,
      onClick: openExportModal,
      disabled: !product?.hasSteps,
      order: 2,
    },
    {
      label: 'Change Owner',
      Icon: IconChangeUser,
      onClick: () =>
        changeProductOwner({
          excludedUserIds: reviewerId ? [reviewerId] : undefined,
        }),
      show: isOwner && !isViewer,
    },
    {
      label: 'Delete',
      Icon: IconTrashFilled,
      onClick: onDelete,
      allowedProductStates: [InstructionState.DRAFT, InstructionState.REQUEST_CHANGE],
      show: isOwner && !isViewer,
      order: 101,
    },
  ];

  const filteredActions = actions.filter((action) => action.show !== false);

  useEffect(() => {
    setIsLoading(isRenameMutationLoading || isDeleteMutationLoading || isChangeOwnerLoading || isFavoriteLoading);
  }, [isRenameMutationLoading, isDeleteMutationLoading, isChangeOwnerLoading, isFavoriteLoading]);

  return { productActions: filteredActions, isLoading };
};
