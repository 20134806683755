import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class ProjectNameDto {
  @ApiProperty({
    description: 'The project id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The name of the project',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;
}

export const ProjectNameSchema = generateClassValidationObject(ProjectNameDto);
