import { useRejectProductRevisionMutation } from '@assemblio/frontend/data-access';
import { ProductContentDto, RejectRevisionDto } from '@assemblio/shared/dtos';
import { notifications } from '@mantine/notifications';
import { useModalContext } from '@assemblio/frontend/components';

export const useRejectProductRevision = () => {
  const reject = useRejectProductRevisionMutation();
  const { openModal } = useModalContext();

  const onRejectRevision = (product: ProductContentDto | undefined, placeholder: string, submitText: string) => {
    if (!product) return;
    openModal('reject-product-revision', {
      product,
      placeholder: placeholder,
      submitText: submitText,
      onRequestChanges: (productId: string, revisionId: string, reason?: string) =>
        rejectRevision({
          productId,
          revisionId,
          description: reason,
        }),
    });
  };

  const rejectRevision = (data: RejectRevisionDto) => {
    reject.mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'reject-revision-success',
            message: 'Revision has been rejected',
          });
        },
      }
    );
  };

  return {
    onRejectRevision,
    isLoading: reject.isLoading,
  };
};
