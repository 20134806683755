import React from 'react';
import { Table, Text } from '@mantine/core';
import { UserEntry } from './UserEntry';
import { UserDto } from '@assemblio/shared/dtos';

const users: UserDto[] = [
  {
    id: '1',
    email: 'hans@assemblio.de',
    firstName: 'Hans',
    lastName: 'Andersen',
    fullName: 'Hans Andersen',
    role: 'editor',
    expirationDate: new Date(),
  },
  {
    id: '2',
    email: 'michelle@assemblio.de',
    firstName: 'Michelle',
    lastName: 'Creandon',
    fullName: 'Michelle Creandon',
    role: 'viewer',
    expirationDate: new Date(),
  },
];

const UserTable: React.FC = () => {
  const handleChangeUserRole = (userId: string, roleId: string) => {
    console.log(`User ${userId} role changed to ${roleId}`);
  };

  const handleRemoveUser = (userId: string) => {
    console.log(`User ${userId} removed`);
  };

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>
            <Text size="sm" variant="medium">
              User
            </Text>
          </Table.Th>
          <Table.Th>
            <Text size="sm" variant="medium">
              Role
            </Text>
          </Table.Th>
          <Table.Th>
            <Text size="sm" variant="medium">
              Last Active
            </Text>
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {users.map((user) => (
          <UserEntry
            key={user.id}
            user={user}
            onChangeUserRole={handleChangeUserRole}
            onRemoveUser={handleRemoveUser}
          />
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default UserTable;
