import { InstructionSequence, InstructionTreeDto } from '@assemblio/shared/next-types';
import {
  AnnotationResponseDto,
  InputDto,
  InstructionDto,
  IntermediateProjectInstructionDto,
  StepGroupDto,
} from '@assemblio/shared/dtos';
import { Assembly, Part } from '@assemblio/type/input';
import produce from 'immer';
import _ from 'lodash';
import { v4 } from 'uuid';
import { transformProjectDtoToProject, modelInit } from '../helper';
import {
  clear as clearProjectIndices,
  ProjectIndices,
  setPartParent,
  useProjectIndices,
} from '../indexes/ProjectIndex';
import { Project, ProjectStore, useProjectStore } from '../stores';
import { SettingsController } from './SettingsController';
import { GLTF } from 'three-stdlib';
import { SequenceController } from './SequenceController';
import { ModelController } from '.';
import { AnnotationController } from './AnnotationController';
import { InstructionState } from '@assemblio/type/instruction';
import { HierarchyController } from '@assemblio/frontend/hierarchy';

export namespace ProjectController {
  export const initAfterLoad = (
    partGroupsData: InstructionTreeDto,
    gltf: GLTF,
    sequenceId: string,
    stepGroupsData: StepGroupDto[],
    instructionData: InstructionDto,
    annotationData: AnnotationResponseDto[],
    currentProductVersion?: number
  ) => {
    const input = {
      assemblies: partGroupsData.partGroups,
      parts: partGroupsData.parts,
    } as InputDto;

    modelInit(gltf, input);

    SequenceController.initSequenceStore(
      sequenceId,
      stepGroupsData,
      instructionData.instructionSequences as InstructionSequence[]
    );

    const owner = instructionData.owner;

    setProjectFromDto({
      projectId: instructionData.project.id,
      instructionId: instructionData.id,
      name: instructionData.name,
      editorSettings: instructionData.editorSettings,
      owner: {
        id: owner.id,
        lastName: owner.lastName,
        firstName: owner.firstName,
        email: owner.email,
      },
      state: instructionData.state,
      input: input,
    });

    currentProductVersion && setCurrentProductVersion(currentProductVersion);
    ModelController.setModel(gltf, input);
    AnnotationController.init(annotationData);
    HierarchyController.init();
  };

  export const createEmptyProject = (): Project => ({
    projectId: v4(),
    instructionId: '',
    name: 'Empty Project',
    owner: {
      id: v4(),
      firstName: '',
      lastName: '',
      email: '',
    },
    state: 'draft' as InstructionState,
    input: {
      id: v4(),
      assemblies: [],
      parts: [],
    },
  });

  export const setNavigatedToComposerFrom = (navTarget: string | undefined) => {
    useProjectStore.setState(
      produce<ProjectStore>((state: ProjectStore) => {
        state.navigatedToComposerFrom = navTarget;
      })
    );
  };

  export const setSelectedExplorerItemId = (itemId: string) => {
    useProjectStore.setState(
      produce<ProjectStore>((state: ProjectStore) => {
        state.selectedExplorerItem = itemId;
      })
    );
  };

  export const setHighlightedInstructionId = (instructionId: string | undefined) => {
    useProjectStore.setState(
      produce<ProjectStore>((state: ProjectStore) => {
        state.highlightedInstructionId = instructionId;
      })
    );
  };

  export const associatePartIndexWithGltfIndex = (partIndex: number, gltfIndex: number) => {
    useProjectIndices.setState(
      produce<ProjectIndices>((state: ProjectIndices) => {
        state.gltfIndexToPartIndexMap.set(gltfIndex, partIndex);
        state.partIndexToGltfIndexMap.set(partIndex, gltfIndex);
      })
    );
  };

  export const associateAssemblyIndexWithGltfIndex = (assemblyIndex: number, gltfIndex: number) => {
    useProjectIndices.setState(
      produce<ProjectIndices>((state: ProjectIndices) => {
        state.gltfIndexToAssemblyIndexMap.set(gltfIndex, assemblyIndex);
        state.assemblyIndexToGltfIndexMap.set(assemblyIndex, gltfIndex);
      })
    );
  };

  export const reset = () => {
    useProjectStore.getState().reset();
    clearProjectIndices();
  };

  export const setProject = (project: Project) => {
    useProjectStore.setState(
      produce<Project>(() => ({
        ...project,
      }))
    );
  };

  export const setProjectFromDto = (projectDto: IntermediateProjectInstructionDto) => {
    const project: Project = transformProjectDtoToProject(projectDto);
    setProject(project);

    const editorSettings = projectDto.editorSettings;
    if (editorSettings) SettingsController.setEditorSettingsFromDto(editorSettings);
  };

  export const setProjectId = (projectId: string) => {
    useProjectStore.setState(
      produce<Project>((state: Project) => {
        state.projectId = projectId;
      })
    );
  };

  export const getPartByProjectIndex = (partIndex: number): Part => {
    return useProjectStore.getState().input.parts[partIndex];
  };

  export const getAssemblyByProjectIndex = (assemblyIndex: number): Assembly => {
    return useProjectStore.getState().input.assemblies[assemblyIndex];
  };

  export const getAssemblyByGltfIndex = (gltfIndex: number): Assembly | undefined => {
    const index = useProjectIndices.getState().gltfIndexToAssemblyIndexMap.get(gltfIndex);
    if (index !== undefined) {
      return getAssemblyByProjectIndex(index);
    }
    return;
  };

  export const getPartProjectIndicesOfAssemblyByGltfIndex = (
    gltfIndex: number,
    addPartsOfSubAssemblies: boolean
  ): number[] => {
    const assembly = getAssemblyByGltfIndex(gltfIndex);
    const parts: number[] = [];
    if (assembly) {
      parts.push(...assembly.parts);
      if (addPartsOfSubAssemblies) {
        assembly.assemblies.forEach((subAssemblyIndex) => {
          const subParts = getPartProjectIndicesOfAssemblyByGltfIndex(
            getAssemblyByProjectIndex(subAssemblyIndex).gltfIndex,
            addPartsOfSubAssemblies
          );
          parts.push(...subParts);
        });
      }
    }

    return parts;
  };

  export const getPartsGltfIndicesOfAssemblyByGltfIndex = (
    gltfIndex: number,
    addPartsOfSubAssemblies: boolean
  ): number[] => {
    const partProjectIndices = getPartProjectIndicesOfAssemblyByGltfIndex(gltfIndex, addPartsOfSubAssemblies);
    return partProjectIndices.map((partIndex) => getPartByProjectIndex(partIndex).gltfIndex);
  };

  export const getPartByGltfIndex = (gltfIndex: number): Part | undefined => {
    const index = useProjectIndices.getState().gltfIndexToPartIndexMap.get(gltfIndex);
    if (index !== undefined) {
      return getPartByProjectIndex(index);
    }
    return;
  };

  export const getPartIndexByGltfIndex = (gltfIndex: number): number | undefined => {
    return useProjectIndices.getState().gltfIndexToPartIndexMap.get(gltfIndex);
  };

  export const getAssemblyIndexByGltfIndex = (gltfIndex: number): number | undefined => {
    return useProjectIndices.getState().gltfIndexToAssemblyIndexMap.get(gltfIndex);
  };

  export const getAssemblyPartIndex = (partIndex: number): number[] => {
    const assembly = useProjectStore.getState().input.assemblies.find((assembly) => assembly.gltfIndex === partIndex);
    if (assembly) {
      const parts = assembly.parts.map((partIndex) => getPartByProjectIndex(partIndex).gltfIndex);
      return _.cloneDeep(parts).concat(
        _.flatMap(assembly.assemblies, (assemblyIndex) => {
          const assemblyId = getAssemblyByProjectIndex(assemblyIndex).gltfIndex;
          return getAssemblyPartIndex(assemblyId);
        })
      );
    } else {
      return [];
    }
  };

  export const getPartGltfIndexById = (partId: string) => {
    return useProjectStore.getState().input.parts.find((part) => part.id === partId)?.gltfIndex;
  };

  export const getParentGltfIndexes = (gltfIndex: number) => {
    const gltfIndexes: Array<number> = [];
    const parentGltfIndex = useProjectIndices.getState().partParents.get(gltfIndex);

    if (parentGltfIndex !== undefined) {
      gltfIndexes.push(parentGltfIndex);
      gltfIndexes.push(...getParentGltfIndexes(parentGltfIndex));
    }

    return gltfIndexes;
  };

  export const setPartParentOnIndex = (part: number, parent: number) => {
    setPartParent(part, parent);
  };

  export const getParent = (gltfIndex: number) => {
    return useProjectIndices.getState().partParents.get(gltfIndex);
  };

  export const mapAssembly = <T>(assembly: Assembly, callback: (item: Part | Assembly) => T): Array<T> => {
    return [callback(assembly)].concat(
      assembly.assemblies
        .flatMap((assembly) => mapAssembly(getAssemblyByProjectIndex(assembly), callback))
        .concat(assembly.parts.map((part) => callback(getPartByProjectIndex(part))))
    );
  };
}

export const setCurrentProductVersion = (version: number) => {
  useProjectStore.setState(
    produce<ProjectStore>((state: ProjectStore) => {
      state.currentVersion = version;
    })
  );
};
