import { useQuery } from '@tanstack/react-query';
import {
  fetchFavoriteProducts,
  fetchInstruction,
  fetchInstructionParts,
  fetchInstructionSteps,
  fetchProductContent,
  fetchProductView,
  fetchRecentProducts,
  fetchSequencesForExport,
  loadInstructionGltf,
} from './instruction.requests';
import {
  ExportModalQuery,
  FetchProductView,
  InstructionStepsQuery,
} from './types/instruction.request.types';
import { ProductQueryDto } from '@assemblio/shared/dtos';

export const useInstructionPartsQuery = (instructionId?: string) => {
  return useQuery(
    ['instruction-parts', instructionId],
    () => {
      return fetchInstructionParts(instructionId);
    },
    {
      enabled: Boolean(instructionId),
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};

export const useInstructionGltfFileQuery = (instructionId?: string) => {
  return useQuery(
    ['instruction-file', instructionId],
    () => {
      return loadInstructionGltf(instructionId);
    },
    { enabled: Boolean(instructionId), cacheTime: 0, refetchOnWindowFocus: false }
  );
};

export const useInstructionStepsQuery = (query: InstructionStepsQuery) => {
  return useQuery(
    ['instruction-sequence-step-groups', query.instructionId],
    () => {
      return fetchInstructionSteps(query);
    },
    {
      enabled: Boolean(query.instructionId),
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};

export const useInstructionQuery = (instructionId: string | undefined) => {
  return useQuery(
    ['instruction', instructionId],
    () => {
      return fetchInstruction(instructionId);
    },
    {
      enabled: Boolean(instructionId),
      cacheTime: 0,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useProductViewQuery = (data: FetchProductView) => {
  return useQuery(
    ['product-view', data.productId],
    () => {
      return fetchProductView(data);
    },
    {
      enabled: Boolean(data.productId),
      cacheTime: 0,
      retry: false,
    }
  );
};

export const useProductOverviewQuery = (productId: string | undefined) => {
  return useQuery(
    ['product', productId],
    () => {
      return fetchProductContent(productId);
    },
    {
      enabled: Boolean(productId),
      cacheTime: 0,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useSequencesForExportQuery = (query: ExportModalQuery) => {
  return useQuery(
    ['export-sequences', query.instructionId],
    () => {
      return fetchSequencesForExport(query.instructionId);
    },
    {
      enabled: Boolean(query.instructionId) && query.modalOpened,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFavoriteProductsQuery = (data?: ProductQueryDto) => {
  return useQuery(
    ['favorite-products'],
    () => {
      return fetchFavoriteProducts(data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useRecentProductsQuery = (data?: ProductQueryDto) => {
  return useQuery(
    ['recent-products'],
    () => {
      return fetchRecentProducts(data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};
