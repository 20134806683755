import { StepController } from '../controller';
import { useAnimationStore, useUIStore } from '../stores';
import { EditorGuardArgs } from './types/machine.types';

export const isStepListNotEmpty = () => {
  return StepController.getStepsCount() > 0;
};

export const isNotFirstStep = () => {
  const currentStep = useAnimationStore.getState().currentClipIndex;
  return currentStep !== 0;
};

export const isEditable = (): boolean => {
  return useUIStore.getState().editable;
};

export const lastPartsGetDeselected = ({ event }: EditorGuardArgs): boolean => {
  return useUIStore.getState().selectedPartSet.size - event.parts.length === 0;
};
