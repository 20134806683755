import { ApiProperty } from '@assemblio/shared/decorators';

export class TenantDto {
  @ApiProperty({
    description: 'Tenant id',
    required: true,
  })
  id!: string;

  @ApiProperty({
    description: 'Tenant name',
    required: true,
  })
  name!: string;

  @ApiProperty({
    description: 'Tenant slug',
    required: true,
  })
  slug!: string;

  @ApiProperty({
    description: 'Tenant expiration date',
    required: false,
  })
  expirationDate?: Date | null;
}
