import React, { FormEvent } from 'react';
import { Modal, Textarea, Button, Group, Text } from '@mantine/core';
import classes from './RequestChangesProductModal.module.scss';
import { ProductContentDto } from '@assemblio/shared/dtos';
import { useFetchLatestRevision } from '@assemblio/frontend/data-access';
import { useForm } from '@mantine/form';

interface RequestChangesProductModalProps {
  product: ProductContentDto;
  placeholder: string;
  submitText: string;
  onRequestChanges: (productId: string, revisionId: string, reason?: string) => void;
  opened: boolean;
  onClose: () => void;
}

export const RejectProductRevisionModal = ({
  product,
  placeholder,
  submitText,
  onRequestChanges,
  opened,
  onClose,
}: RequestChangesProductModalProps) => {
  const form = useForm<{
    changeRequestText: string | undefined;
  }>({
    initialValues: {
      changeRequestText: undefined,
    },
    validate: {
      changeRequestText: (value) => {
        if (!value) return 'A reason is required';
        if (value.trim().length > 300) {
          return 'Text must not be more than 300 characters long';
        }
        return null;
      },
    },
  });

  const { data: productRevision, isLoading } = useFetchLatestRevision(product.id);

  const handleSubmitChangeRequest = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (form.validate().hasErrors) return;
    if (!productRevision) return;
    onRequestChanges(product.id, productRevision.id, form.values.changeRequestText);
    onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Request changes"
      classNames={{
        body: classes.custom_request_modal_body,
        title: classes.custom_request_modal_title,
      }}
    >
      <form onSubmit={handleSubmitChangeRequest}>
        <Textarea
          px="lg"
          {...form.getInputProps('changeRequestText')}
          placeholder={placeholder}
          autosize
          minRows={4}
          maxLength={300}
          classNames={{
            input: classes.custom_text_area,
          }}
        />
        <Text pt="xs" px="lg" pb="xl" size="xs" c="text-secondary">
          {form.getValues().changeRequestText?.length || 0}/300 characters
        </Text>

        <Group className={classes.custom_Request_modal_footer} justify="flex-end" px="lg" py="lg" gap={'md'}>
          <Button variant={'secondary'} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isLoading} type={'submit'} variant={'primary'}>
            {submitText}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
