import { useAuthStore, useCreateProductRevision } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { useModalContext } from '@assemblio/frontend/components';
import { useExplorerRouteParams } from '../ExplorerRouteParams.hook';
import { UserRoles } from '@assemblio/type/user';

export const useRequestProductReview = (productId?: string) => {
  const { openModal } = useModalContext();
  const currentUserId = useAuthStore((state) => state.userId);
  const { productId: routeBasedProductId } = useExplorerRouteParams();
  const createRevisionMutation = useCreateProductRevision();

  const openRequestReviewModal = () => {
    openModal('select-user-modal', {
      title: 'Request Review',
      userRoleLabel: 'Reviewer',
      buttonText: 'Request Review',
      exclude: [UserRoles.viewer, UserRoles.editor],
      onSubmitUser: handleRequestReview,
    });
  };

  const handleRequestReview = (userId: string) => {
    const relevantId = productId || routeBasedProductId;
    if (!relevantId) {
      console.error('No product id found to request review for');
      return;
    }
    createRevisionMutation.mutate(
      {
        instructionId: relevantId,
        reviewerId: userId,
        requesteeId: currentUserId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'request-review',
            message: 'Review requested',
          });
        },
      }
    );
  };

  return {
    requestReview: openRequestReviewModal,
    isLoading: createRevisionMutation.isLoading,
  };
};
