import { useInstructionImageQuery } from '@assemblio/frontend/data-access';
import { InstructionImageColor, InstructionImageSize } from '@assemblio/type/instruction';
import { useEffect, useState } from 'react';
import { useComputedColorScheme } from '@mantine/core';

const PLACEHOLDER_PATH_DARK = 'assets/editor/product-image-placeholder-small-dark.webp';
const PLACEHOLDER_PATH_LIGHT = 'assets/editor/product-image-placeholder-small-light.webp';
const PLACEHOLDER_PATH_LARGE_DARK = 'assets/editor/product-image-placeholder-large-dark.webp';
const PLACEHOLDER_PATH_LARGE_LIGHT = 'assets/editor/product-image-placeholder-large-light.webp';

const getPlaceholderPath = (colorScheme: InstructionImageColor, size: InstructionImageSize): string => {
  if (size === InstructionImageSize.LARGE) {
    return colorScheme === 'dark' ? PLACEHOLDER_PATH_LARGE_DARK : PLACEHOLDER_PATH_LARGE_LIGHT;
  }

  return colorScheme === 'dark' ? PLACEHOLDER_PATH_DARK : PLACEHOLDER_PATH_LIGHT;
};

export const useProductImage = (productId: string | undefined, size: InstructionImageSize) => {
  const colorScheme = useComputedColorScheme('dark', { getInitialValueInEffect: false });
  const {
    data: imageData,
    isSuccess,
    refetch,
  } = useInstructionImageQuery({
    productId: productId,
    query: {
      size,
      color: colorScheme as InstructionImageColor,
    },
    enabled: !!productId,
  });

  const [imageSrc, setImageSrc] = useState<string>(getPlaceholderPath(colorScheme as InstructionImageColor, size));

  useEffect(() => {
    // Update the placeholder based on color scheme
    setImageSrc(getPlaceholderPath(colorScheme as InstructionImageColor, size));

    // Refetch image when color scheme changes
    if (productId) {
      refetch();
    }
  }, [colorScheme, productId, refetch, size]);

  useEffect(() => {
    if (isSuccess && imageData) {
      const objectURL = URL.createObjectURL(imageData);
      setImageSrc(objectURL);
      return () => URL.revokeObjectURL(objectURL);
    }
    return;
  }, [imageData, isSuccess]);

  return {
    imageSrc,
  };
};
