import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { ExportSelectionDto } from './selection-steps.dto';
import { ResolutionEnum, CodecEnum, VideoExportTypes, DocumentExportType } from '@assemblio/type/export';

const allTypes = [...Object.values(VideoExportTypes), ...Object.values(DocumentExportType)];

class ExportItem {
  @joiSchemaProperty(Joi.string().valid(...allTypes))
  @ApiProperty({ description: 'The exported type', required: true, enum: allTypes })
  type!: DocumentExportType | VideoExportTypes;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(ResolutionEnum))
      .when('type', {
        is: Joi.string().valid(...Object.values(VideoExportTypes)),
        then: Joi.required(),
        otherwise: Joi.forbidden(),
      })
  )
  @ApiProperty({ description: 'Resolution, for only video types', required: false, enum: ResolutionEnum })
  resolution?: ResolutionEnum;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(CodecEnum))
      .when('type', {
        is: Joi.string().valid(...Object.values(VideoExportTypes)),
        then: Joi.required(),
        otherwise: Joi.forbidden(),
      })
  )
  @ApiProperty({ description: 'Codec, for only video types', required: false, enum: CodecEnum })
  codec?: CodecEnum;

  @joiSchemaProperty(Joi.boolean().optional())
  @ApiProperty({ description: 'Add parts table to the preface', default: true })
  showParts?: boolean;

  @joiSchemaProperty(Joi.boolean().optional())
  @ApiProperty({ description: 'Add operationalResources table to the preface', default: true })
  showResources?: boolean;
}

const ExportItemSchema = generateClassValidationObject(ExportItem);

export class MultiExportDto {
  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'The Instruction ID', required: true })
  instructionId!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'The file name', required: true })
  fileName!: string;

  @joiSchemaProperty(Joi.array().items().required())
  @ApiProperty({ description: 'The selection steps', required: true })
  selection!: ExportSelectionDto[];

  @joiSchemaProperty(Joi.array().items(ExportItemSchema.required()).required().min(1))
  @ApiProperty({ description: 'The export types', required: true, min: 1, isArray: true, type: [ExportItem] })
  exports!: ExportItem[];
}

export const MultiExportSchema = generateClassValidationObject(MultiExportDto);
