import { InstructionTreeDto, SequenceExport } from '@assemblio/shared/next-types';
import { GLTF, GLTFLoader } from 'three-stdlib';
import { axiosHandler } from '../utils/axios-utils';
import {
  ChangeInstructionState,
  ChangeProductOwner,
  FetchProductView,
  InstructionStepsQuery,
  ProductSetFavorite,
  RenameInstruction,
  SetInstructionEditorSettings,
} from './types/instruction.request.types';
import {
  InstructionDto,
  ProductAccessDto,
  ProductContentDto,
  ProductOverviewDto,
  ProductQueryDto,
} from '@assemblio/shared/dtos';

export const fetchInstructionParts = async (id: string | undefined): Promise<InstructionTreeDto> => {
  return typeof id === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler('get', `/parts/instruction/${id}/tree`);
};

export const loadInstructionGltf = async (instructionId: string | undefined): Promise<GLTF> => {
  return typeof instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler<ArrayBuffer>('get', `/v1/instructions/${instructionId}/files/gltf`, {
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'application/octet-stream' },
      }).then((data) => {
        return new Promise((resolve, reject) => {
          const loader = new GLTFLoader();
          loader.parse(
            data,
            '',
            (gltf: GLTF) => resolve(gltf),
            (event) => reject(event.error)
          );
        });
      });
};

export const fetchInstructionSteps = async (query: InstructionStepsQuery): Promise<any[]> => {
  return typeof query.instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler('get', `/v1/instructions/${query.instructionId}/step-groups`, {
        params: { reverse_order: query.reverseOrder },
      });
};

export const fetchStepGroupsOfVersion = async (query: {
  instructionId: string | undefined;
  version: number | undefined;
}): Promise<any[]> => {
  return typeof query.instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler(
        'get',
        `/instructions/${query.instructionId}/version/${query.version}/step-groups?reverse_order=false`
      );
};

export const fetchInstruction = async (instructionId: string | undefined): Promise<InstructionDto> => {
  return typeof instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler('get', `/instructions/${instructionId}`);
};

export const fetchProductContent = async (productId: string | undefined): Promise<ProductContentDto> => {
  return typeof productId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler('get', `/v1/products/${productId}`);
};

export const saveInstruction = async (instructionId: string | undefined): Promise<InstructionDto[]> => {
  return axiosHandler('post', `/instructions/${instructionId}/save`);
};

export const fetchSequencesForExport = async (instructionId: string | undefined): Promise<SequenceExport[]> => {
  return typeof instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `/instructions/${instructionId}/sequences/export`);
};

export const setInstructionEditorSettings = ({ id, data }: SetInstructionEditorSettings) => {
  return axiosHandler('put', `/instructions/${id}/settings`, {
    data,
  });
};

export const renameInstruction = ({ id, data }: RenameInstruction) => {
  return axiosHandler('patch', `/instructions/${id}/rename`, {
    data,
  });
};

export const changeProductOwner = (data: ChangeProductOwner) => {
  return axiosHandler('put', `/instructions/${data.productId}/owner/${data.newOwnerId}`);
};

export const changeStateOfInstruction = (data: ChangeInstructionState) => {
  return axiosHandler('patch', `/instructions/${data.instructionId}/state`, {
    data,
  });
};

export const deleteInstruction = async (instructionId: string) => {
  return axiosHandler('delete', `/instructions/${instructionId}`);
};

export const setInstructionFavorite = async (data: ProductSetFavorite): Promise<void> => {
  return typeof data.productId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler(data.action === 'add' ? 'patch' : 'delete', `v1/products/${data.productId}/favourite`);
};

export const fetchFavoriteProducts = async (data?: ProductQueryDto): Promise<ProductOverviewDto[]> => {
  return axiosHandler('get', `v1/products/favourites`, { params: data });
};

export const fetchRecentProducts = async (data?: ProductQueryDto): Promise<ProductOverviewDto[]> => {
  return axiosHandler('get', `v1/products/recent`, { params: data });
};

export const fetchProductView = async (data: FetchProductView): Promise<ProductAccessDto> => {
  return typeof data.productId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `v1/products/${data.productId}/${data.view}`);
};
