import { usePatchProductReviewer } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { useModalContext } from '@assemblio/frontend/components';
import { ProductContentDto } from '@assemblio/shared/dtos';

export const useChangeProductReviewer = (product?: ProductContentDto) => {
  const patchReviewerMutation = usePatchProductReviewer();
  const { openModal } = useModalContext();

  const changeProductReviewer = () => {
    openModal('select-user-modal', {
      title: 'Change Reviewer',
      userRoleLabel: 'Reviewer',
      buttonText: 'Change Reviewer',
      onSubmitUser: handleChangeReviewer,
      preselectedUserId: product?.revision?.reviewer.id,
    });
  };

  const handleChangeReviewer = (userId: string) => {
    if (!product || !product.revision) return;
    patchReviewerMutation.mutate(
      {
        productId: product.id,
        data: {
          revisionId: product.revision.id,
          reviewerId: userId,
        },
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'change-reviewer',
            message: 'Reviewer has been changed',
          });
        },
      }
    );
  };

  return { changeProductReviewer, isLoading: patchReviewerMutation.isLoading };
};
