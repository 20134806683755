import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { InstructionState } from '@assemblio/type/instruction';
import { FolderDto } from '../folder';
import { UserSummaryDto } from '../user';
import { SyncOverviewDto, SyncOverviewSchema } from '../instruction';
import { ProjectNameDto, ProjectNameSchema } from '../project';
import { RevisionDto, RevisionSchema, VersionDto, VersionSchema } from '../revision';

export class ProductContentDto {
  @ApiProperty({
    description: 'The product id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The name of the product',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({
    description: 'The parent project',
    required: false,
    type: ProjectNameDto,
  })
  @joiSchemaProperty(ProjectNameSchema.required())
  project!: ProjectNameDto;

  @ApiProperty({
    description: 'The parent project',
    required: false,
  })
  folder!: FolderDto;

  @ApiProperty({
    description: 'The state of the product',
    required: true,
  })
  state!: InstructionState;

  @ApiProperty({
    description: 'When the product was last edited',
    required: false,
  })
  lastEditedAt?: Date;

  @ApiProperty({
    description: 'When the product was created',
    required: false,
  })
  createdAt!: Date;

  @ApiProperty({
    description: 'The owner of the product',
    required: false,
  })
  ownedBy!: UserSummaryDto;

  @ApiProperty({
    description: 'The creator of the product',
    required: false,
  })
  createdBy!: UserSummaryDto;

  @ApiProperty({
    description: 'if the product is a favorite if the current user',
    required: true,
  })
  isFavorite!: boolean;

  @ApiProperty({
    description: 'The description of the product',
    required: false,
  })
  hasSteps!: boolean;

  @ApiProperty({
    description: 'The source file of the product',
    required: false,
  })
  sourceFile!: {
    name: string;
    type: string;
  };

  @ApiProperty({
    description: 'The sync status of the product',
    required: true,
  })
  @joiSchemaProperty(SyncOverviewSchema)
  sync!: SyncOverviewDto;

  @ApiProperty({
    description: 'The current revision',
    required: false,
  })
  @joiSchemaProperty(RevisionSchema)
  revision?: RevisionDto;

  @ApiProperty({
    description: 'The active version',
    required: true,
  })
  @joiSchemaProperty(VersionSchema)
  version?: VersionDto;
}

export const ProductContentSchema = generateClassValidationObject(ProductContentDto);
