import React, { useEffect, useState } from 'react';
import { Group, Text, TextProps, useMantineTheme } from '@mantine/core';
import classes from './ExpandableText.module.scss';
import cx from 'clsx';

interface ExpandableTextProps {
  text: string;
  maxLines?: number;
  textProps?: TextProps;
}

export const ExpandableText = ({ text, textProps, maxLines = 1 }: ExpandableTextProps) => {
  const theme = useMantineTheme();
  const ref = React.useRef<HTMLParagraphElement>(null);
  const minimum = maxLines * pxToNumber(theme.lineHeights[textProps?.size || 'md']) * theme.scale;
  const [isTruncated, setIsTruncated] = useState(true);
  const [shouldTruncate, setShouldTruncate] = useState(false);
  const [height, setHeight] = useState(minimum);

  useEffect(() => {
    if (!ref.current) return;
    const { scrollHeight } = ref.current;
    setShouldTruncate(scrollHeight > minimum);
    setHeight(scrollHeight > minimum ? minimum : scrollHeight);
  }, [ref, minimum]);

  const toggle = () => {
    if (!ref.current) return;
    const { scrollHeight } = ref.current;
    setHeight(isTruncated ? scrollHeight : minimum);
    setIsTruncated(!isTruncated);
  };

  return (
    <Group gap={0} align={'flex-start'} w={'100%'} h={height} className={classes.wrapper}>
      <Text {...textProps} w={'100%'} ref={ref}>
        {text}&nbsp;
        {shouldTruncate && !isTruncated && (
          <Text td={'underline'} className={classes.truncate__button} span onClick={toggle}>
            show less
          </Text>
        )}
      </Text>
      {shouldTruncate && isTruncated && (
        <Text
          mt={minimum - minimum / maxLines}
          className={cx(classes.truncate__button, classes.truncate__button__more)}
          onClick={toggle}
        >
          <Text span td={'underline'}>
            show more
          </Text>
        </Text>
      )}
    </Group>
  );
};

function pxToNumber(px: string): number {
  return parseInt(px.replace('px', ''), 10);
}
