import { useUIStore } from '@assemblio/frontend/stores';
import { Alert, Divider, Group, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { shallow } from 'zustand/shallow';
import { CameraCenterButton } from './CameraCenterButton';
import { TransformActionSwitch } from './TransformActionSwitch';
import { AnnotationChooser } from './UI/AnnotationChooser';
import { ColorPicker } from './UI/ColorPicker';
import { PreviewSwitch } from './PreviewSwitch';
import { RotationSnapSettings } from './RotationSnapSettings';
import { SidebarTextRendererButton } from './SidebarTextRendererButton';
import { SelectionSwitch } from './SelectionSwitch';
import classes from './Viewport.module.scss';
import { ShowStepResourceButton } from './ShowStepResourceButton';
import { IconInfoCircle } from '@tabler/icons-react';

export const ViewportControls = () => {
  const { cameraControlsActive, toolBarActive, stepSelected, editable } = useUIStore(
    (state) => ({
      cameraControlsActive: state.viewportControlsActive,
      toolBarActive: state.toolbarActive,
      stepSelected: state.selectedStep !== undefined,
      editable: state.editable,
    }),
    shallow
  );

  return (
    <Stack className={classes.controls__wrapper} data-view-mode={!editable} justify={'center'} align={'center'}>
      {!editable && <ViewModeAlertBanner />}
      <Group p={'xs'} gap="xs" align="center" mr={'xs'} className={classes.controls}>
        {editable && cameraControlsActive && (
          <>
            {/* <TransformModeSwitch /> */}
            <CameraCenterButton />
            <Divider my={'xs'} orientation="vertical" />
            <TransformActionSwitch />
            <Divider my={'xs'} orientation="vertical" />
            <RotationSnapSettings />
            <Divider my={'xs'} orientation="vertical" />
            <PreviewSwitch />
            <Divider my={'xs'} orientation="vertical" />
            <SelectionSwitch />
            <Divider my={'xs'} orientation="vertical" />
            <ColorPicker />
            <Divider orientation="vertical" />
          </>
        )}

        <SidebarTextRendererButton />
        <ShowStepResourceButton />
        {editable && toolBarActive && stepSelected && <AnnotationChooser />}
      </Group>
    </Stack>
  );
};

const ViewModeAlertBanner = () => (
  <Alert
    p={0}
    h={'2.5rem'}
    radius={0}
    w={'100%'}
    className={classes.view_mode_alert}
    variant="light"
    color="blue"
    c={'text-secondary'}
  >
    <Group gap={'xs'}>
      <Text>View mode</Text>
      <Tooltip label={'The product can not be edited while it is going through a review process'}>
        <ThemeIcon c={'text-secondary'} variant={'transparent'}>
          <IconInfoCircle size={14} />
        </ThemeIcon>
      </Tooltip>
    </Group>
  </Alert>
);
