import { SyncProfileRelationDto } from '@assemblio/shared/next-types';
import { ProductContentDto } from '@assemblio/shared/dtos';
import { useModalContext } from '@assemblio/frontend/components';
import { SyncProfileState } from '@assemblio/type/instruction';
import { useEditSyncProfile } from '../syncProduct';

export const useResolveSyncIssues = (product?: ProductContentDto, syncableProfiles?: SyncProfileRelationDto[]) => {
  const { openModal } = useModalContext();
  const { onUpdateSyncProfile } = useEditSyncProfile();
  const handleResolveSyncIssues = () => {
    if (!product) return;
    const syncRelation = syncableProfiles?.find((profile) => profile.state === SyncProfileState.UNRESOLVED);
    if (!syncRelation) return;
    openModal('resolve-sync-issues-modal', {
      syncRelation,
      onUpdateSyncProfile,
    });
  };
  return { resolveSyncIssues: handleResolveSyncIssues };
};
