import { IconChangeUser, IconRequestReview } from '@assemblio/design-system';
import { notifications } from '@mantine/notifications';
import {
  useCreateProductRevision,
  useFetchLatestRevision,
  useRemoveProductRevision,
} from '@assemblio/frontend/data-access';
import {
  useHasFeatureFlag,
  useChangeProductReviewer,
  useChangeProductRevisionState,
  useRequestProductChanges,
  useRequestProductReview,
} from '../../../../../hooks';
import { FeatureFlag } from '@assemblio/type/feature-flag';
import { InstructionState } from '@assemblio/type/instruction';
import { ReviewStateSelection } from '../components/ReviewStateSelection/ReviewStateSelection';
import { ProductAction, ProductActionHookInput } from '../types/productActions.types';
import { ReviewStatus } from '@assemblio/type/revision';
import { IconAlertCircleFilled } from '@tabler/icons-react';
import { useModalContext } from '@assemblio/frontend/components';

export const useProductReviewActions = ({ product, userState }: ProductActionHookInput) => {
  const { isOwner, isViewer, isReviewer } = userState;
  const reviewsEnabled = useHasFeatureFlag(FeatureFlag.BetaReview);
  const { changeProductReviewer } = useChangeProductReviewer(product);
  const { handleChangeState } = useChangeProductRevisionState();
  const { onRequestChanges } = useRequestProductChanges();
  const { requestReview } = useRequestProductReview(product?.id);
  const { openModal } = useModalContext();
  const createRevisionMutation = useCreateProductRevision();
  const removeRevisionMutation = useRemoveProductRevision();
  const { data: productRevision, isLoading } = useFetchLatestRevision(product?.id);

  const handleRemoveReview = () => {
    if (!product) return;
    if (!product.revision) return;
    removeRevisionMutation.mutate(
      {
        id: product.id,
        revisionId: product.revision?.id,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'remove-review',
            message: 'Review removed',
            color: 'green',
          });
        },
      }
    );
  };

  const onChangeProductRevisionState = (newState: ReviewStatus) => {
    if (!product) return;
    handleChangeState(product, productRevision?.id, newState);
  };

  const actions: ProductAction[] = [
    {
      component: <ReviewStateSelection onChangeState={onChangeProductRevisionState} state={productRevision?.state} />,
      allowedProductStates: [InstructionState.IN_REVIEW],
      show: isReviewer,
    },
    {
      label: 'Change Reviewer',
      Icon: IconChangeUser,
      onClick: changeProductReviewer,
      allowedProductStates: [InstructionState.IN_REVIEW],
      show: isOwner || isReviewer,
    },
    {
      label: 'Request Review',
      Icon: IconRequestReview,
      onClick: requestReview,
      disabled: !product?.hasSteps,
      allowedProductStates: [InstructionState.DRAFT, InstructionState.REQUEST_CHANGE],
      show: isOwner,
      tooltipText: !product?.hasSteps
        ? 'Review can only be requested if the product has associated steps'
        : 'Review can not be requested while product is in review',
    },
    {
      label: 'Remove Review',
      Icon: IconRequestReview,
      onClick: () => handleRemoveReview(),
      disabled: !product?.hasSteps,
      allowedProductStates: [InstructionState.IN_REVIEW],
      show: isOwner,
    },
    {
      label: 'Request more changes',
      Icon: IconAlertCircleFilled,
      onClick: () =>
        openModal('confirmation-modal', {
          label: 'Request changes',
          message: 'Are you sure you want to request more changes?',
          onConfirm: () => onRequestChanges(product?.id, productRevision?.id),
        }),
      disabled: !product?.hasSteps,
      allowedProductStates: [InstructionState.APPROVED],
      allowedUserRoles: ['manager'],
    },
    {
      label: 'Enable Edit',
      Icon: IconAlertCircleFilled,
      onClick: () =>
        openModal('confirmation-modal', {
          label: 'Re-enable Edit',
          message: 'Are you sure you want to enable editing this file?',
          onConfirm: () => onRequestChanges(product?.id, productRevision?.id),
        }),
      disabled: !product?.hasSteps,
      allowedProductStates: [InstructionState.APPROVED],
      show: isOwner,
    },
  ];

  const showReviewActions = reviewsEnabled && isViewer;

  const filteredActions = showReviewActions ? [] : actions.filter((action) => action.show !== false);

  return {
    productActions: filteredActions,
    isLoading: showReviewActions ? false : createRevisionMutation.isLoading || isLoading,
  };
};
