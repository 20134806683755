import { useInstructionSync } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { ProductContentDto, SyncProfileConfig } from '@assemblio/shared/dtos';
import { useModalContext } from '@assemblio/frontend/components';
import { SyncProfileRelationDto } from '@assemblio/shared/next-types';

export const useSyncProduct = (product?: ProductContentDto, syncableProfiles?: SyncProfileRelationDto[]) => {
  const syncMutation = useInstructionSync();
  const { openModal } = useModalContext();
  const handleSync = () => {
    if (!product) return;
    openModal('execute-sync-modal', {
      productId: product.id,
      productName: product.name,
      syncableProfiles: syncableProfiles?.map((profile) => profile.from) ?? [],
      onSyncProduct: commitSync,
    });
  };
  const commitSync = (
    baseVariantId: string,
    targetVariantId: string,
    config: SyncProfileConfig,
    projectId?: string
  ) => {
    syncMutation.mutate(
      {
        sourceId: baseVariantId,
        targetId: targetVariantId,
        config: config,
        projectId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'sync-instruction-with-source-success',
            message: 'Initialized synchronization',
            color: 'green',
          });
        },
      }
    );
  };

  return {
    syncProduct: handleSync,
    isLoading: syncMutation.isLoading,
  };
};
