import { Badge, BadgeProps } from '@mantine/core';
import classes from './Tag.module.scss';
import { PropsWithChildren, ReactNode } from 'react';
import { InstructionState } from '@assemblio/type/instruction';
import { IconAlertCircleFilled, IconCircleCheckFilled, IconRefresh, IconSettingsFilled } from '@tabler/icons-react';
import { IconDraft } from '../Icons';
import { ReviewStatus } from '@assemblio/type/revision';
import cx from 'clsx';

type ApplicableStates =
  | Extract<
      InstructionState,
      | InstructionState.DRAFT
      | InstructionState.APPROVED
      | InstructionState.IN_REVIEW
      | InstructionState.INITIALIZING
      | InstructionState.REQUEST_CHANGE
    >
  | ReviewStatus;

type StateIconMapType = {
  [key in ApplicableStates]: ReactNode;
};

const StateIconMap: StateIconMapType = {
  [InstructionState.APPROVED]: <IconCircleCheckFilled size={16} />,
  [InstructionState.IN_REVIEW]: <IconRefresh size={16} />,
  [InstructionState.DRAFT]: <IconDraft />,
  [InstructionState.INITIALIZING]: <IconSettingsFilled size={16} />,
  [InstructionState.REQUEST_CHANGE]: <IconAlertCircleFilled size={16} />,
  [ReviewStatus.REJECTED]: <IconAlertCircleFilled size={16} />,
  [ReviewStatus.IN_REVIEW]: <IconRefresh size={16} />,
};

interface TagProps extends BadgeProps {
  state?: InstructionState | ReviewStatus;
  variant?: 'default' | 'primary';
}

export const Tag = ({ children, state, variant, ...props }: PropsWithChildren<TagProps>) => {
  const Icon = StateIconMap[state as ApplicableStates];

  return (
    <Badge
      leftSection={Icon}
      variant={variant}
      py={'xs'}
      h={'fit-content'}
      {...props}
      className={cx(classes.tag, props.className)}
    >
      {state ? state : children}
    </Badge>
  );
};
