import { Select } from '@mantine/core';
import { IconChevronDown, IconRefresh } from '@tabler/icons-react';
import { ReviewStatus } from '@assemblio/type/revision';
import classes from './ReviewStateSelection.module.scss';
import { transformEnumToSelectData } from '../../../../../../util';

interface ReviewStateSelectionProps {
  onChangeState: (newState: ReviewStatus) => void;
  state?: ReviewStatus;
}

type StateLabelMap = {
  [key in ReviewStatus]: string;
};

const REVIEW_STATUS_LABELS: StateLabelMap = {
  [ReviewStatus.APPROVED]: 'Approve',
  [ReviewStatus.IN_REVIEW]: 'In Review',
  [ReviewStatus.REJECTED]: 'Reject',
};

const handleSelectChange = (value: string | null, onChangeState: (newState: ReviewStatus) => void) => {
  if (value) {
    const newState = Object.keys(REVIEW_STATUS_LABELS).find(
      (key) => REVIEW_STATUS_LABELS[key as ReviewStatus] === value
    ) as ReviewStatus;
    if (newState) {
      onChangeState(newState);
    }
  }
};

export const ReviewStateSelection = ({ onChangeState, state = ReviewStatus.IN_REVIEW }: ReviewStateSelectionProps) => {
  return (
    <Select
      classNames={{
        input: classes.input,
        root: classes.root,
      }}
      leftSection={<IconRefresh size={16} />}
      rightSection={<IconChevronDown size={12} />}
      onChange={(value) => handleSelectChange(value, onChangeState)}
      value={REVIEW_STATUS_LABELS[state]}
      data={transformEnumToSelectData(REVIEW_STATUS_LABELS)}
    />
  );
};
