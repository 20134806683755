import { Button, Collapse, Paper, PasswordInput, TextInput, Title } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useDisclosure, useFocusTrap } from '@mantine/hooks';
import classes from './Login/Login.module.scss';
import { Auth0Login } from './Login/hooks/auth0Login.hook';

interface LoginFormProps {
  form: UseFormReturnType<{
    email: string;
    password: string;
  }>;
  onSubmit: (
    values: {
      email: string;
      password: string;
    },
    event: React.FormEvent<HTMLFormElement> | undefined
  ) => void;
  loading: boolean;
}

export const LoginForm = ({ form, onSubmit, loading }: LoginFormProps) => {
  const focusTrapRef = useFocusTrap();
  const { shouldUseAuth0, onForceAuth0, isAuth0Active } = Auth0Login();

  const [showLegacyLogin, { toggle: toggleLegacyLogin }] = useDisclosure(!shouldUseAuth0);

  return (
    <form ref={focusTrapRef} onSubmit={form.onSubmit((values, event) => onSubmit(values, event))}>
      <Paper className={classes.form_wrapper} withBorder shadow="md" p={'xl'} mt={30} radius="md">
        <Title ta={'center'} order={1} mb={'xl'}>
          Log in
        </Title>

        {shouldUseAuth0 && (
          <Button
            onClick={onForceAuth0}
            disabled={!isAuth0Active}
            fullWidth
            data-cy={'login-button-auth0'}
            mt="xl"
            variant={showLegacyLogin ? 'secondary' : 'primary'}
          >
            Sign in via Assemblio SSO
          </Button>
        )}

        <Collapse mt="xl" in={showLegacyLogin}>
          <>
            <TextInput
              label="Email"
              name="email"
              placeholder="your@email.com"
              withAsterisk
              {...form.getInputProps('email')}
              data-cy="login-input-email"
            />
            <PasswordInput
              label="Password"
              name="password"
              placeholder="Your password"
              withAsterisk
              mt="md"
              {...form.getInputProps('password')}
              data-cy="login-input-password"
            />

            <Button loading={loading} type="submit" fullWidth mt="xl" data-cy="login-button-submit" variant={'primary'}>
              {loading ? 'Signing in' : 'Sign in'}
            </Button>
          </>
        </Collapse>
        {!showLegacyLogin && (
          <Button
            loading={loading}
            onClick={toggleLegacyLogin}
            fullWidth
            mt="xl"
            data-cy="login-button-use-legacy"
            variant={'secondary'}
          >
            {'Use legacy sign in'}
          </Button>
        )}
      </Paper>
    </form>
  );
};
