export enum ProjectContentSortProps {
  name = 'name',
  updatedAt = 'updatedAt',
  createdAt = 'createdAt',
  state = 'state',
}

export enum ProjectSortProps {
  name = 'name',
  updatedAt = 'updatedAt',
  createdAt = 'createdAt',
}
