import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { ReviewStatus } from '@assemblio/type/revision';

export class VersionDto {
  @ApiProperty({
    description: 'The version id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The version',
    enum: ReviewStatus,
    required: true,
  })
  number?: number;
}

export const VersionSchema = generateClassValidationObject(VersionDto);
