import { useEffect, useState } from 'react';
import { SortingOption } from '../../../types';
import { useSearchParams } from 'react-router-dom';

interface Return<T extends string | undefined> {
  sortingValue: SortingOption<T> | undefined;
  handleSort: (value: string) => void;
}

const SORT_QUERY_IDENTIFIER = 'sortBy';

export const useSortingOptions = <T extends string | undefined>(options: SortingOption<T>[]): Return<T> => {
  const [sortingValue, setSortingValue] = useState<SortingOption<T> | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSort = (value: string) => {
    const sortingOption = options.find((option) => option.value === value);
    const isSelectedOption = sortingOption?.value === sortingValue?.value;
    setSortingValue(isSelectedOption ? undefined : sortingOption);
  };

  //Set initial selected options from query string
  useEffect(() => {
    const presetQueryParams = searchParams.get(SORT_QUERY_IDENTIFIER);

    // Toggle Query if filter is part of url parameters
    if (presetQueryParams) {
      handleSort(presetQueryParams);
      setSortingValue(options.find((option) => option.value === presetQueryParams));
    }
  }, []);

  // Update Query string when selectedOptions change
  useEffect(() => {
    searchParams.delete(SORT_QUERY_IDENTIFIER);

    let newParams = searchParams;

    if (sortingValue) {
      newParams = new URLSearchParams([...searchParams, [SORT_QUERY_IDENTIFIER, sortingValue.value]]);
    } else {
      searchParams.delete(SORT_QUERY_IDENTIFIER);
    }

    setSearchParams(newParams, {
      replace: true,
    });
  }, [sortingValue]);

  return {
    sortingValue: sortingValue,
    handleSort,
  };
};
