import { ProjectContentDto, CreateProjectDto, ProjectDto } from '@assemblio/shared/dtos';
import { axiosHandler } from '../utils/axios-utils';
import { NextProjectUpdate } from './types/project.mutation.types';
import { FetchProject, NextProjectMove } from '@assemblio/frontend/data-access';

export const deleteProject = async (projectId: string) => {
  return axiosHandler('delete', `v1/projects/${projectId}`);
};

export const updateNextProject = async (project: NextProjectUpdate) => {
  return axiosHandler('put', `/projects/${project.projectId}`, {
    data: project.projectData,
  });
};
export const fetchProjectContent = async (data: FetchProject): Promise<ProjectContentDto> => {
  return typeof data.projectId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `/v1/projects/${data.projectId}`, { params: data.queryData });
};

export const createEmptyProject = async (project: CreateProjectDto): Promise<ProjectDto> => {
  return axiosHandler('post', '/projects', { data: project });
};

export const moveProject = async (options: NextProjectMove) => {
  return axiosHandler('patch', `/v1/projects/${options.projectId}/folder/${options.targetFolderId}`);
};
