import React, { useEffect, useState } from 'react';
import { Modal, Button, Group, Text, ScrollArea, Stack, Divider } from '@mantine/core';
import { SearchField } from '@assemblio/design-system';
import classes from './SelectUserModal.module.scss';
import { UserCard } from './components/UserCard';
import { IconCircleXFilled } from '@tabler/icons-react';
import { useAuthStore, useTenantUsersQuery } from '@assemblio/frontend/data-access';
import { UserDto } from '@assemblio/shared/dtos';
import { useDebouncedValue } from '@mantine/hooks';
import { UserRoles } from '@assemblio/type/user';
import { WithModalBaseProps } from '../../types';

export interface SelectUserModalProps {
  preselectedUserId?: string;
  title?: string;
  userRoleLabel?: string;
  buttonText?: string;
  exclude?: UserRoles[];
  excludedUserIds?: string[];
  onSubmitUser?: (userId: string) => void;
}

export const SelectUserModal = ({
  opened,
  onClose,
  preselectedUserId,
  title = 'Select User',
  userRoleLabel = 'User',
  buttonText = 'Select User',
  exclude = [UserRoles.viewer],
  excludedUserIds,
  onSubmitUser,
}: WithModalBaseProps<SelectUserModalProps>) => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(preselectedUserId || null);

  const userId = useAuthStore((state) => state.userId);

  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const [debouncedSearchString] = useDebouncedValue(searchString, 200);

  const {
    data: userData,
    isFetching,
    refetch,
  } = useTenantUsersQuery({
    exclude: exclude?.join(','),
    searchBy: debouncedSearchString,
  });

  const handleUserSelect = (user: UserDto) => {
    setSelectedUserId(user.id);
  };

  const handleSubmit = () => {
    if (!selectedUserId || !onSubmitUser) return;
    onSubmitUser(selectedUserId);
    onClose();
  };

  const filterSelf = (user: UserDto) => user.id !== userId;

  const filterExcluded = (user: UserDto) => !excludedUserIds?.includes(user.id);

  useEffect(() => {
    if (!isFetching) {
      refetch();
    }
  }, [debouncedSearchString]);

  return (
    <Modal
      mb={'lg'}
      opened={opened}
      onClose={onClose}
      title={title}
      centered
      closeButtonProps={{
        icon: <IconCircleXFilled size={16} color={'var(--text-secondary)'} onClick={onClose} />,
      }}
    >
      <Stack gap={0}>
        <Text variant={'medium'} c={'text-primary'} mb={'sm'} px={'lg'}>
          Select {userRoleLabel}
        </Text>
        <SearchField
          value={searchString}
          onChange={(event) => setSearchString(event.currentTarget.value)}
          variant={'filled'}
          placeholder={`Search ${userRoleLabel}`}
          c={'text-secondary'}
        />

        <ScrollArea.Autosize mah={350}>
          {userData?.items
            .filter(filterSelf)
            .filter(filterExcluded)
            .map((user) => (
              <UserCard key={user.id} user={user} isSelected={selectedUserId === user.id} onSelect={handleUserSelect} />
            ))}
        </ScrollArea.Autosize>
      </Stack>
      <Divider c={'border-secondary'} />
      <Group className={classes.modalFooter} justify="flex-end" px={'lg'} gap={'md'}>
        <Button variant={'secondary'} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={!selectedUserId} variant={'primary'}>
          {buttonText}
        </Button>
      </Group>
    </Modal>
  );
};
