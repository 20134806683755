import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { InstructionState } from '@assemblio/type/instruction';
import { UserSummaryDto } from '../../user';
import { FolderDto } from '../../folder';
import { ProjectNameDto } from '../../project';

export class ManagedProductOverviewDto {
  @ApiProperty({
    description: 'The product id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  id!: string;

  @ApiProperty({
    description: 'The tenant id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  tenantId!: string;

  @ApiProperty({
    description: 'The name of the product',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({
    description: 'The state of the product',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  state!: InstructionState;

  @ApiProperty({
    description: 'The date the product was last updated',
    required: true,
  })
  updatedAt!: Date;

  @ApiProperty({
    description: 'The date the product was created',
    required: true,
  })
  createdAt!: Date;

  @ApiProperty({
    description: 'The date the product was deleted',
    required: false,
  })
  deletedAt?: Date;

  @ApiProperty({
    description: 'The thumbnail path for the product',
    required: true,
  })
  owner!: UserSummaryDto;

  @ApiProperty({
    description: 'The folder for the product',
    required: true,
  })
  folder!: FolderDto;

  @ApiProperty({
    description: 'The number of parts in the product',
    required: true,
  })
  partCount!: number;

  @ApiProperty({
    description: 'The project for the product',
    required: true,
  })
  project!: ProjectNameDto;
}

export const ManagedProductOverviewSchema = generateClassValidationObject(ManagedProductOverviewDto);
