import {
  fetchAnnotationsAPI,
  fetchInstructionAPI,
  fetchInstructionPartsAPI,
  fetchInstructionSteps,
  loadInstructionGltf,
} from '@assemblio/frontend/data-access';
import { StepGroupDto } from '@assemblio/shared/dtos';
import {
  MediaExportMachineEvent,
  MediaExportOptions,
  MediaExportProtocolEvent,
  MediaExportWorkingData,
} from '@assemblio/type/export';
import { Color } from 'three';
import { GLTF } from 'three-stdlib';
import { assertEvent, assign } from 'xstate';
import { AnimationController, SettingsController } from '../controller';
import { createLineMesh } from '../helper';
import { mediaExportActor } from './media-export.machine';
import { FetchInstructionResult, MediaExportContext, MediaExportEvent } from './types/media-export.machine.types';

export const projectHasSteps = ({ context }: { context: MediaExportContext }): boolean => {
  return context.stepGroups !== undefined && context.stepGroups.flatMap((group) => group.steps || [])?.length > 0;
};

export const applyStepGroupSelection = ({ context }: { context: MediaExportContext }) => {
  if (!context.workingData?.selection || !context.stepGroups || context.currentTask?.action === 'cover') return;
  const clipIds = context.workingData.selection.reduce((clipIds, { sequenceId, stepGroupIds }) => {
    return clipIds.concat(
      stepGroupIds
        .flatMap((id) => {
          const group = context.stepGroups?.find((group) => {
            const stepGroup = group as StepGroupDto;
            return stepGroup.instructionSequenceId === sequenceId && stepGroup.id === id;
          }) as StepGroupDto | undefined;
          if (group) {
            return group.steps.flatMap((step) => {
              const clips = AnimationController.getClipIdForStepId(step.id);
              return step.playWithAbove ? [] : [clips?.main, clips?.prelude];
            });
          }
          return undefined;
        })
        .filter((value) => value !== undefined) as Array<string>
    );
  }, new Array<string>());
  AnimationController.applySequence(clipIds);
};

export const clearContext = assign(() => {
  return {
    exportMessage: undefined,
    instruction: undefined,
    instructionParts: undefined,
    stepGroups: undefined,
    annotations: undefined,
    gltf: undefined,
  };
});

const dispatchErrorEvent = (message: string) => {
  mediaExportActor.send({
    type: MediaExportMachineEvent.ERROR,
    message,
  });
};

export const fetchInstruction = async (context: MediaExportContext): Promise<FetchInstructionResult> => {
  if (!context.workingData) {
    dispatchErrorEvent(`Error fetching instruction from server.`);
    return Promise.reject();
  }

  const instructionId = context.workingData.instructionId;
  const instruction = await fetchInstructionAPI(instructionId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatchErrorEvent(`Error fetching instruction ${instructionId} from server.`);
      return undefined;
    });
  if (!instruction) return Promise.reject();
  const instructionParts = await fetchInstructionPartsAPI(instructionId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      dispatchErrorEvent(`Error fetching instruction ${instructionId} from server.`);
      return undefined;
    });
  if (!instructionParts) return Promise.reject();
  let stepGroups: Array<any> | undefined = [];
  if (context.workingData.selection) {
    stepGroups = await Promise.all(
      context.workingData.selection.map((selection) => {
        return fetchInstructionSteps({
          instructionId: instruction.id,
          reverseOrder: false,
        });
      })
    )
      .then((result) => {
        return result.flat();
      })
      .catch((error) => {
        console.log(error);
        dispatchErrorEvent(`Error fetching step groups from server.`);
        return undefined;
      });
  }
  if (!stepGroups) stepGroups = [];
  const gltf = await loadInstructionGltf(instruction.id)
    .then((result) => result)
    .catch((error) => {
      console.log(error);
      dispatchErrorEvent(`Error fetching GLTF from server.`);
      return undefined;
    });
  if (!gltf) return Promise.reject();
  const annotations = await fetchAnnotationsAPI(instructionId)
    .then((result) => result)
    .catch((error) => {
      console.log(error);
      dispatchErrorEvent(`Error fetching Annotations from server.`);
      return undefined;
    });
  if (annotations === undefined) return Promise.reject();
  return { gltf, stepGroups, instructionParts, instruction, annotations };
};

export const downloadGLTF = async (context: MediaExportContext) => {
  if (!context.instruction) {
    dispatchErrorEvent('Instruction not initialized on Context.');
    return Promise.reject();
  }

  const fileId = context.instruction.id;
  await loadInstructionGltf(fileId)
    .then((gltf: GLTF) => {
      assign({ gltf });
      createLineMesh(gltf.scene);
    })
    .catch((error) => {
      console.log(error);
      dispatchErrorEvent(`Failed loading GLTF file ${fileId} from server.`);
    });
};

export const setViewportOptions = ({ context }: { context: MediaExportContext }) => {
  if (context.currentTask) {
    SettingsController.setGridSettingsByKey('enabled', context.currentTask.viewport.showGrid === true);
    const color = new Color(context.currentTask.viewport.backgroundColor).getHexString();
    SettingsController.setViewportColor(`#${color}`);
  }
};

// Following console logs were useful for debugging with the Video Renderer and should therefore be untouched
export const notifyReadyToRender = () => {
  console.log('[media-export.actions] readyToRender');
  const progress = AnimationController.getAnimationProgress();
  window.readyToRender(progress);
};

export const notifyReadyToCapture = () => {
  console.log('[media-export.actions] notifyReadyToCapture');
  const progress = AnimationController.getAnimationProgress();
  window.readyToCapture(progress);
};

export const notifyComplete = () => {
  console.log('[media-export.actions] notifyComplete');
  window.finished();
};

export const notifyInvalid = () => {
  console.log('[media-export.actions] notifyInvalid');
  window.invalid();
};

export const notifyError = ({ event }: { event: MediaExportEvent }) => {
  console.log('[media-export.actions] notifyError');
  assertEvent(event, MediaExportMachineEvent.ERROR);
  window.error(event.message);
};

export const notifyAwaitingCommand = () => {
  console.log('[media-export.actions] notifyAwaitingCommand');
  window.awaitingCommand();
};

export const notifyTaskCompleted = () => {
  window.taskCompleted();
};

export const advance = ({ context }: { context: MediaExportContext }) => {
  if (context.currentTask?.action !== 'video') return;
  context.workingData && AnimationController.advance(1000 / context.currentTask.frameRate);
};

export const registerEventHandlers = () => {
  window.addEventListener(MediaExportProtocolEvent.LOAD_PROJECT, (event: Event) => {
    const exportEvent = event as CustomEvent<MediaExportWorkingData>;
    mediaExportActor.send({ type: MediaExportMachineEvent.PREPARE, message: exportEvent.detail });
  });
  window.addEventListener(MediaExportProtocolEvent.READY_TO_RECEIVE, (event: Event) => {
    mediaExportActor.send({ type: MediaExportMachineEvent.START });
  });
  window.addEventListener(MediaExportProtocolEvent.FRAME_RECORDED, (event: Event) => {
    mediaExportActor.send({ type: MediaExportMachineEvent.CAPTURED });
  });
  window.addEventListener(MediaExportProtocolEvent.SET_COMMAND, (event: Event) => {
    const commandEvent = event as CustomEvent<MediaExportOptions>;
    mediaExportActor.send({ type: MediaExportMachineEvent.EXECUTE, task: commandEvent.detail });
  });
  window.addEventListener(MediaExportProtocolEvent.EXPORT_COMPLETE, (event: Event) => {
    mediaExportActor.send({ type: MediaExportMachineEvent.FINISHED });
  });
};

export const checkProjectValidity = ({ context }: { context: MediaExportContext }) => {
  if (!projectHasSteps({ context })) {
    throw new Error("Project doesn't have steps");
  }
};

export const createAnimations = () => {
  AnimationController.createAnimations({
    onAnimationComplete: () => mediaExportActor.send({ type: MediaExportMachineEvent.STOP }),
  });
};
