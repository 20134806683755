import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class ProjectDto {
  @ApiProperty({
    description: 'The project id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({
    description: 'The name of the project',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({
    description: 'The thumbnail name',
    required: false,
  })
  @joiSchemaProperty(Joi.string().optional())
  thumbnailPath?: string;

  @ApiProperty({
    description: 'The owner id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  ownerId!: string;
}

export const ProjectSchema = generateClassValidationObject(ProjectDto);
