export const IconViewNotesOn = ({ size = 16 }: { size?: number }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0C3.54535 0 3.1093 0.180611 2.78782 0.502103C2.46633 0.823593 2.28571 1.25963 2.28571 1.71429V8.34799C3.0061 7.99421 3.8675 7.71429 4.81057 7.71429C6.13585 7.71429 7.29983 8.26706 8.13785 8.80082C9.00174 9.35104 9.71896 10.0098 10.1932 10.4913C11.3352 11.6506 11.3352 13.4922 10.1932 14.6515C9.81413 15.0365 9.27981 15.5345 8.63942 16H14.2857C14.7403 16 15.1765 15.8194 15.4979 15.4979C15.8194 15.1765 16 14.7403 16 14.2857V4.57143C16 4.41987 15.9398 4.27453 15.8327 4.16737L11.8327 0.167368C11.7255 0.0602039 11.5801 0 11.4286 0H4ZM4.81057 9.14286C3.87378 9.14286 2.98309 9.53929 2.25072 10.0057C1.51098 10.4769 0.878381 11.0545 0.445661 11.4938C-0.148553 12.097 -0.148554 13.0458 0.445661 13.649C0.87838 14.0883 1.51098 14.6659 2.25072 15.1371C2.98309 15.6035 3.87378 16 4.81057 16C5.74736 16 6.63806 15.6035 7.37042 15.1371C8.11016 14.6659 8.74277 14.0883 9.17549 13.649C9.76969 13.0458 9.76969 12.097 9.17549 11.4938C8.74277 11.0545 8.11016 10.4769 7.37042 10.0057C6.63806 9.53929 5.74736 9.14286 4.81057 9.14286ZM6.23912 12.5714C6.23912 13.3605 5.59952 14 4.81055 14C4.02157 14 3.38198 13.3605 3.38198 12.5714C3.38198 11.7824 4.02157 11.1429 4.81055 11.1429C5.59952 11.1429 6.23912 11.7824 6.23912 12.5714Z"
        fill="currentColor"
      />
    </svg>
  );
};
