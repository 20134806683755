import { useEffect } from 'react';
import { EventController } from '@assemblio/frontend/stores';
import { useQueryClient } from '@tanstack/react-query';
import { useExplorerRouteParams } from '../../../hooks/ExplorerRouteParams.hook';
import { ProductMessage, RoomEvents } from '@assemblio/type/socket';
import { ProjectContentDto } from '@assemblio/shared/dtos';
import produce from 'immer';
import { InstructionImageSize } from '@assemblio/type/instruction';

/**
 * Hook that subscribes to all relevant Websocket events for a project.
 */
export const useProjectWebsocketListeners = () => {
  const queryClient = useQueryClient();

  const { projectId } = useExplorerRouteParams();

  const handleProductUpdate = (event: ProductMessage) => {
    queryClient.setQueryData<ProjectContentDto>(['project', projectId], (data?: ProjectContentDto) =>
      produce(data, (draft) => {
        if (!draft?.products?.length) return;
        const idx = draft.products.findIndex(
          (product) => product.id === event.target.productId && product.project.id === event.target.projectId
        );
        if (idx === -1) return;

        const { deleted, message, coverUpdated, ...productChanges } = event.payload;

        if (coverUpdated) {
          queryClient.invalidateQueries(['instruction-image', draft.products[idx].id, InstructionImageSize.MEDIUM], {
            refetchType: 'all',
          }, {
            cancelRefetch: false,
          });
        }

        if (deleted) draft.products.splice(idx, 1);
        else
          draft.products[idx] = {
            ...draft.products[idx],
            ...productChanges,
          };
      })
    );
  };

  useEffect(() => {
    if (!projectId) return;
    EventController.subscribeRoom(
      {
        roomEvent: RoomEvents.Product,
        projectId,
      },
      handleProductUpdate
    );
    return () => {
      EventController.unsubscribeRoom({
        roomEvent: RoomEvents.Product,
        projectId,
      });
    };
  }, []);
};
