import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class RejectRevisionDto {
  @ApiProperty({
    description: 'The id of the product',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  productId!: string;

  @ApiProperty({
    description: 'The id of the revision',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  revisionId!: string;

  @ApiProperty({
    description: 'The description of the change',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required().max(300))
  description?: string;
}

export const rejectRevisionSchema = generateClassValidationObject(RejectRevisionDto);
