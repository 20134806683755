import { useApproveProductRevision } from '@assemblio/frontend/data-access';
import { ReviewStatus } from '@assemblio/type/revision';
import { ProductContentDto } from '@assemblio/shared/dtos';
import { useRejectProductRevision } from '@assemblio/frontend/explorer';

export const useChangeProductRevisionState = () => {
  const approveRevisionMutation = useApproveProductRevision();
  const { onRejectRevision } = useRejectProductRevision();
  const handleChangeState = (product: ProductContentDto, revisionId: string | undefined, newState: ReviewStatus) => {
    if (!revisionId) {
      return;
    }
    if (newState === ReviewStatus.APPROVED) {
      approveRevisionMutation.mutate({
        productId: product.id,
        revisionId: revisionId,
      });
    } else if (newState === ReviewStatus.REJECTED) {
      onRejectRevision(product, 'What changes are needed before this product can be approved?', 'Reject');
    }
  };

  return { handleChangeState };
};
